import React, { useEffect, useState } from 'react'
import { urlGenerator } from '../../lib/url';
import Newstetter from './newsletter';

const Footer = ({ layout }) => {
    const [gaUsers, setGaUsers] = useState(null)
    const [showNewsletterModal, setShowNewletersModal] = useState(false)
    const [eservices, setEservices] = useState(layout.e_services);
    const [menus, setMenus] = useState(layout.menus);

    var annee = (new Date()).getFullYear();
    useEffect(() => {
        window.gapi && window.gapi.client
            .init({
                discoveryDocs: [
                    "https://content.googleapis.com/discovery/v1/apis/analyticsreporting/v4/rest",
                ],
            })
            .then(() => {
                window.gapi.client.analyticsreporting.reports.batchGet({
                    "resource": {
                        "reportRequests": [
                            {
                                "viewId": '248658527',
                                "dateRanges": [
                                    {
                                        "startDate": "2021-08-01",
                                        "endDate": "today"
                                    }
                                ]
                            }
                        ]
                    }
                })
                    .then(response => {
                        // console.log(response.result)
                        setGaUsers(response.result.reports[0].data.totals[0].values);
                        //JSON.stringify(gaUsers);
                    },
                        function (err) { console.error("Execute error", err); });
            });
    }, [])

    const closeModal = () => {
        setShowNewletersModal(false)
    }

    return (
        <div class="pg-footer">
            <footer class="footer">
                {/* <svg class="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100"
                    preserveAspectRatio="none">
                    <path class="footer-wave-path"
                        d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
                </svg> */}
                <div class="footer-content">
                    <div class="footer-content-column">
                        <div class="footer-logo" style={{ paddingRight: '30px', marginTop: '20px' }}>
                            <a class="footer-logo-link" href="#">
                                <span class="hidden-link-text">LOGO</span>
                                <img src={'/img/logo-MAEP-white.png'} alt="LOGO" class="img-fluid" />
                            </a>
                        </div>
                        <div class="footer-menu">
                            <div class="text-center">
                            {gaUsers && gaUsers > 0 && layout.parametres.filter(p => p.libUnique == "showVisiteurs" && p.valeur == 1).length > 0 &&  <button
                                    class="btn btn-outline-success mt-3 mx-auto text-center mb-4 waves-effect waves-light visitcountbtn text-center">
                                    <span class="visitCount">{gaUsers != null ? gaUsers : ''}</span>
                                    <span class="visitcount"> <i class="fas fa-user"></i></span>
                                    
                                </button>}
                            </div>

                        </div>
                    </div>
                    <div class="footer-content-column">
                        <div class="footer-menu">
                            <h2 class="footer-menu-name"> ACCES DIRECT</h2>
                            <ul id="menu-legal" class="footer-menu-list">
                                {
                                    menus.map(m => (
                                        m.name == 'DOCUMENTS' ? m.enfants.map(m1 => (
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                                                <a href={urlGenerator(m1)}>{m1.name}</a>
                                            </li>
                                        )) : <div></div>
                                    ))
                                }

                            </ul>
                        </div>
                    </div>
                    <div class="footer-content-column">
                        <div class="footer-menu">
                            <h2 class="footer-menu-name"> Services</h2>
                            <ul id="menu-quick-links" class="footer-menu-list">
                                {
                                    eservices.map(m => (
                                        <li class="menu-item menu-item-type-custom menu-item-object-custom">
                                            <a target="_blank" rel="noopener noreferrer" href={m.url}>{m.libelle}</a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div class="footer-content-column">
                        <div class="footer-menu">
                            <h2 class="footer-menu-name"> NOS CONTACTS</h2>
                            <ul id="menu-get-started" class="footer-menu-list">
                                <li class="menu-item menu-item-type-post_type menu-item-object-product">
                                    <a href="/contactez-nous">
                                        <button type='button' className="btn btn-warning">
                                            Contacter le Ministère
                                        </button>
                                    </a>
                                </li>
                            </ul>
                            <div style={{ height: "100px" }}>
                                <div className="text-white fw-light mb-3 mt-3 ">
                                    <button onClick={() => setShowNewletersModal(true)} type='button' className="btn btn-warning">Je
                                        m'inscris à la newsletter
                                    </button>
                                </div>
                                <Newstetter show={showNewsletterModal} toggle={closeModal} />
                            </div>
                            <p>
                                <div style={{ color: '#fff', fontWeight: '14px' }}> Suivez-nous sur</div>
                                <div className="mt-2">
                                    {layout.parametres.filter(p => p.libUnique == "facebook_url").length > 0 && <a href={`${layout.parametres.filter(p => p.libUnique == "facebook_url")[0].valeur} `} ><i className="fab fa-facebook" style={{ color: '#fff', fontSize: '22px', paddingRight: '10px' }}></i></a>}
                                    {layout.parametres.filter(p => p.libUnique == "twitter_url").length > 0 && <a href={`${layout.parametres.filter(p => p.libUnique == "twitter_url")[0].valeur} `} ><i className="fab fa-twitter-square" style={{ color: '#fff', fontSize: '22px', paddingRight: '10px' }}></i></a>}
                                    {layout.parametres.filter(p => p.libUnique == "youtube_url").length > 0 && <a href={`${layout.parametres.filter(p => p.libUnique == "youtube_url")[0].valeur} `} ><i className="fab fa-youtube" style={{ color: '#fff', fontSize: '22px', paddingRight: '10px' }}></i></a>}
                                    {/* <a href="#"><i className="fab fa-facebook" style={{ color: '#fff', fontSize: '22px', paddingRight: '10px' }}></i></a> &nbsp;
                                    <a href="#"><i className="fab fa-twitter" style={{ color: '#fff', fontSize: '22px', paddingRight: '10px' }}></i></a> &nbsp;
                                    <a href="#"><i className="fab fa-youtube" style={{ color: '#fff', fontSize: '22px', paddingRight: '10px' }}></i></a> &nbsp; */}
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="footer-copyright">
                    <div class="footer-copyright-wrapper">
                        <p class="footer-copyright-text">
                            <a class="footer-copyright-link text-white" href={process.env.REACT_APP_BASE_URL} target="_self"> © {annee} Ministère de l'Agriculture
                                de l'Elevage et de la pêche </a>
                                |
                            <a class="footer-copyright-link text-white" href={process.env.REACT_APP_BASE_URL + '/content/mentions-legales'} target="_self"> Mentions Légales </a>
                        </p>
                    </div>
                </div>
                <div id="flagContainer">
                    <div class="row flag-look-like">
                        <div class="col-md-4 green-block"></div>
                        <div class="col-md-4 yellow-block"></div>
                        <div class="col-md-4 red-block"></div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
