import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash'
import $ from 'jquery'
import { AppContext } from '.';
import { urlGenerator } from '../../lib/url';
const Menu = ({ menus, e_services, mecef, layout }) => {
    // console.log(e_services)

    const [firstLevelMenu, setFirstLevelMenu] = useState(null);
    const [secondLevelMenu, setSecondLevelMenu] = useState(null);
    const [eservice, setEservice] = useState(false);
    const [search, setSearch] = useState("");
    const [menutop, setMenuTop] = useState(menus.filter(m => m.parent == null));
    const [fixedtop, setFixedTop] = useState(false)


    const { appState, setAppState } = useContext(AppContext)
    let { parent_menus } = appState

    useEffect(() => {
        window.addEventListener('scroll', function () {
            let navbar = document.getElementById('navbar_top')
            setTimeout(function () {
                if (navbar.classList.contains('fixed-top')) {
                    document.getElementById('navbar-brand').classList.remove('show4Mobile');
                } else {
                    document.getElementById('navbar-brand').classList.add('show4Mobile');
                }
            }, 500)
            if (window.scrollY > 90) {
                $('#alertinfo_top').addClass('fixed-top')
                $('#navbar_top').addClass('fixed-top ')

                const navbar_height = document.querySelector('.navbar').offsetHeight;
                // document.body.style.paddingTop = navbar_height + 'px';
                $('body').removeClass('fixed-top')

            } else {
                $('#alertinfo_top').removeClass('fixed-top')
                $('#navbar_top').removeClass('fixed-top ')

                //document.body.style.paddingTop = '0';
            }
        });
    }, [])


    return (
        <div className={`bckColor`} id="menu-part">
            <nav id="navbar_top" className={`navbar_top navbar navbar-expand-lg pt-0 pb-0 `}>
                <div className="row">
                    <div className="head-content2 logo-caption">
                        <a class=" me-2 pb-0" href="/">
                            <img src={'/img/logo-MAEP.png'} alt="logo" width='250px' class="site-logo" />
                        </a>
                    </div>
                    <div className="container-fluid head-content1 pt-2 pb-2">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                            <i className="fas fa-bars secdText"></i>
                        </button>
                        <a className="navbar-brand show4Mobile animate__flipInY" id="navbar-brand" href="#">
                        </a>
                        {layout.parametres.filter(p => p.libUnique == "showServiceOnline" && p.valeur == 1).length > 0 &&
                            <button className="navbar-toggler btn btn-sm btn-outline-warning text-uppercase text-white esmobile" type="button" id="showEservice2" onClick={() => { setEservice(!eservice) }}>
                                {
                                    layout.parametres.filter(p => p.libUnique == "titleServiceOnline").length > 0 ? ` ${layout.parametres.filter(p => p.libUnique == "titleServiceOnline")[0].valeur} ` : 'Services en ligne'
                                }


                                {/*<i className="fas fa-ellipsis-v "></i>*/} 
                            </button>
                        }

                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        {/*<button class="navbar-toggler waves-effect waves-light secBckColor" type="button"
                            aria-expanded="false" aria-label="Toggle navigation" id="showEservice2">
                            <i class="fas fa-th"></i>
                        </button>*/}
                        <div className="collapse navbar-collapse" id="main_nav">
                            {/* <div className="input-group mb-3 pb-3 mt-2 border-bottom show4Mobile">
                            <input type="text" className="form-control" placeholder="Que recherchez-vous ?" value={search} onChange={(event) => setSearch(event.target.value)}
                                aria-label="Recipient's username" aria-describedby="recherche" />
                            <a href={`/recherche/${search}`} className="input-group-text bg-white secdText" id="recherche"><i
                                className="fas fa-search"></i></a>
                        </div> */}
                            <ul className="navbar-nav mx-auto " style={{ fontSize: '12px' }}>
                                {_.sortBy(menutop, ['position']).map(m => (
                                    <li key={m.id} className={`nav-item ${(m.enfants && m.enfants.length > 0) && 'dropdown'} `}><a className={`nav-link ${m.id == parent_menus && 'active'} ${(m.enfants && m.enfants.length > 0) && 'dropdown-toggle '} ${firstLevelMenu && (m == firstLevelMenu) && 'show'} `} data-bs-toggle={(m.enfants && m.enfants.length > 0) && 'dropdown '} onClick={() => setFirstLevelMenu(m == firstLevelMenu ? null : m)} aria-expanded="true" href={urlGenerator(m)}> {m.name} </a>
                                        {/* First Level */}
                                        {
                                            m.name == 'ACCUEIL' || m.name == "APPELS D'OFFRES" ? <ul className=""></ul> : (m.enfants && m.enfants.length > 0) && m.name == "LE MINISTERE" ?
                                                <ul style={{ fontSize: '12px' }} className={`dropdown-menu ${firstLevelMenu && (m == firstLevelMenu) && 'show'}`}>
                                                    <li key={m.enfants[1].id} className={`nav-item `}><a className={`dropdown-item `} href={urlGenerator(m.enfants[1])}> {m.enfants[1].name} </a></li>
                                                    <li key={m.enfants[2].id} className={`nav-item `}><a className={`dropdown-item `} href={urlGenerator(m.enfants[2])}> {m.enfants[2].name} </a></li>
                                                    <li key={m.enfants[5].id} className={`nav-item `}><a className={`dropdown-item `} href={urlGenerator(m.enfants[5])}> {m.enfants[5].name} </a></li>
                                                    <li key={m.enfants[10].id} className={`nav-item `}><a className={`dropdown-item `} href={urlGenerator(m.enfants[10])}> {m.enfants[10].name} </a></li>
                                                    <li key={m.enfants[0].id} className={`nav-item `}><a className={`dropdown-item `} href={urlGenerator(m.enfants[0])}> {m.enfants[0].name} </a></li>
                                                    <li key={m.enfants[9].id} className={`nav-item `}><a className={`dropdown-item `} href={urlGenerator(m.enfants[9])}> {m.enfants[9].name} </a></li>
                                                    <li className={`nav-item `}><a className={`dropdown-item `} href="/ministere/sous-menus"> Voir plus<i className="fas fa-long-arrow-alt-right" style={{ paddingLeft: '10px' }}></i> </a></li>
                                                </ul>
                                                :
                                                <ul style={{ fontSize: '12px' }} className={`dropdown-menu ${firstLevelMenu && (m == firstLevelMenu) && 'show'}`}>
                                                    {_.sortBy(m.enfants, ['position']).map(m1 => (
                                                        <li key={m1.id} className={`nav-item ${(m1.enfants && m1.enfants.length > 0) && 'dropdown'}`}><a className={`dropdown-item text-uppercase  ${(m1.enfants && m1.enfants.length > 0) && ' '} ${secondLevelMenu && (m1 == secondLevelMenu) && 'show'} `} data-bs-toggle={(m1.enfants && m1.enfants.length > 0) && 'dropdown '} onClick={() => setSecondLevelMenu(m1 == secondLevelMenu ? null : m1)} href={urlGenerator(m1)}>  {m1.name} {m1.enfants.length > 0 && <i className="fas fa-caret-right"></i>}</a>
                                                            {/* Second Level */}

                                                            {
                                                                (m1.enfants && m1.enfants.length > 0) && <ul style={{ fontSize: '12px' }} className={`submenu ${secondLevelMenu && (m1 == secondLevelMenu) && 'show'}`}>
                                                                    {
                                                                        _.sortBy(m1.enfants, ['position']).map(m2 => (
                                                                            <li key={m2.id} className={`nav-item `}><a className={`dropdown-item `} href={urlGenerator(m2)}> {m2.name} </a></li>
                                                                        ))}
                                                                </ul>
                                                            }

                                                        </li>
                                                    ))}

                                                </ul>
                                        }

                                    </li>
                                ))}
                                {/* <li className="nav-item">
                                                <div class="traducoes" id="traducoes">
                                                    <div id="google_translate_element"></div>
                                                </div>
                                            </li> */}


                                {layout.parametres.filter(p => p.libUnique == "showServiceOnline" && p.valeur == 1).length > 0 && <li className="nav-item hide4mobile" id="showEservice">
                                    <a
                                        className="nav-link showSearch ps-3 btn ms-auto d-block btn-outline-warning btn-sm" style={{ fontSize: '12px' }}
                                        href="#" onClick={() => { setEservice(!eservice) }}> <small className="fas fa-table"></small>
                                        {
                                            layout.parametres.filter(p => p.libUnique == "titleServiceOnline").length > 0 ? ` ${layout.parametres.filter(p => p.libUnique == "titleServiceOnline")[0].valeur}` : ' Services'
                                        }
                                        &nbsp;</a></li>}

                                {/* <li className="nav-item search-dropdown hide4mobile">
                                <div className="search-box">
                                    <input type="text" placeholder="Que recherchez-vous ?" className="search-input" value={search} onChange={(event) => setSearch(event.target.value)} />
                                    <a href={`/recherche/${search}`} className="search-btn">
                                        <i className="fas fa-search"></i>
                                    </a>
                                </div>
                                <a className="nav-link dropbtn showSearch  waves-light">
                                    <i className="fas fa-search"></i>
                                </a>
                                <div id="myDropdown" className="dropdown-content">

                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" value={search} aria-label="Recipient's username"
                                            aria-describedby="basic-addon2" />
                                        <span className="input-group-text" id="basic-addon2"><i
                                            className="fas fa-search"></i></span>
                                    </div>
                                    <div className="searchResultArea">
                                        <h6 className="text-center">Que recherchez-vous ?</h6>
                                    </div>
                                </div>
                            </li> */}
                                {/* <li className="nav-item show4Mobile border-top"><a className="nav-link" href="#"> Guide
                            d’utilisation E-services </a></li>
                            <li className="nav-item show4Mobile"><a className="nav-link" href="#"> Imprimés</a></li>
                            <li className="nav-item show4Mobile"><a className="nav-link" href="#"> Foire aux questions </a></li>
                            <li className="nav-item show4Mobile"><a className="nav-link" href="#"> Nous joindre </a></li> */}
                                {/* <li className='nav-item'><a className='nav-link btn btn-warning text-dark inversebtn' aria-expanded="true" href='#'> Plus (+) </a></li> */}
                            </ul>

                        </div>

                    </div>
                </div>

                {/* <div className="topshareBtn hide4mobile">
                    <div className="topshareBtn-content">

                        {layout.parametres.filter(p => p.libUnique == "facebook_url").length > 0 && <a className="z-depth-2" href={`${layout.parametres.filter(p => p.libUnique == "facebook_url")[0].valeur} `} style={{ color: '#fff' }}><i className="topsocial-icons fab fa-facebook"></i></a>}
                        {layout.parametres.filter(p => p.libUnique == "twitter_url").length > 0 && <a className="z-depth-2" href={`${layout.parametres.filter(p => p.libUnique == "twitter_url")[0].valeur} `} style={{ color: '#fff' }}><i className="topsocial-icons fab fa-twitter-square"></i></a>}
                        {layout.parametres.filter(p => p.libUnique == "youtube_url").length > 0 && <a className="z-depth-2" href={`${layout.parametres.filter(p => p.libUnique == "youtube_url")[0].valeur} `} style={{ color: '#fff' }}><i className="topsocial-icons fab fa-youtube"></i></a>}
                        {layout.parametres.filter(p => p.libUnique == "linkedin_url").length > 0 && <a className="z-depth-2" href={`${layout.parametres.filter(p => p.libUnique == "linkedin_url")[0].valeur} `} style={{ color: '#fff' }}><i className="topsocial-icons fab fa-linkedin-in"></i></a>}
                    </div>
                    <span className="z-depth-2"><i className="fas fa-thumbs-up"></i></span>

                </div> */}
            </nav>
            <div id="eserviceSidenav" className="eservice-sidenav" style={{ width: eservice ? 400 : 0, padding: eservice ? 5 : 0 }}>
                <a href="#" onClick={() => { setEservice(!eservice) }} className=" btn-floating closebtn  waves-light"
                    id="closeEserviceNav">&times;</a>
                <div className="card mb-2">
                    <h6 className="card-title textPrimary border-bottom p-2">Les Services</h6>
                    <div className="card-body">
                        {
                            e_services && <div className="row">
                                <div className="col-md-4 eservice-custom">
                                    <i class="fas fa-wrench"></i><br />
                                    <a key={e_services[0].id} href={`services/${e_services[0].libelle}`} className="" aria-current="true" style={{ fontSize: 10, alignContent: 'center' }}>
                                        {e_services[0].libelle}
                                    </a>
                                </div>
                                {e_services.length > 1 && <div className="col-md-4 eservice-custom">
                                    <i class="fas fa-wrench"></i><br />
                                    <a key={e_services[1].id} href={`services/${e_services[1].libelle}`} className="" aria-current="true" style={{ fontSize: 10, alignContent: 'center' }}>
                                        {e_services[1].libelle.slice(0, 80)}
                                    </a>
                                </div>}
                                {e_services.length > 2 && <div className="col-md-4 eservice-custom">
                                    <i class="fas fa-wrench"></i><br />
                                    <a key={e_services[2].id} href={`services/${e_services[2].libelle}`} className="" aria-current="true" style={{ fontSize: 10, alignContent: 'center' }}>
                                        {e_services[2].libelle.slice(0, 80)}
                                    </a>
                                </div>}
                                {e_services.length > 3 && <div className="col-md-4 eservice-custom">
                                    <i class="fas fa-wrench"></i><br />
                                    <a key={e_services[3].id} href={`services/${e_services[3].libelle}`} className="" aria-current="true" style={{ fontSize: 10, alignContent: 'center' }}>
                                        {e_services[3].libelle.slice(0, 80)}
                                    </a>
                                </div>}
                                {e_services.length > 4 && <div className="col-md-4 eservice-custom">
                                    <i class="fas fa-wrench"></i><br />
                                    <a key={e_services[4].id} href={`services/${e_services[4].libelle}`} className="" aria-current="true" style={{ fontSize: 10, alignContent: 'center' }}>
                                        {e_services[4].libelle.slice(0, 80)}
                                    </a>
                                </div>}
                                {e_services.length > 5 && <div className="col-md-4 eservice-custom">
                                    <i class="fas fa-wrench"></i><br />
                                    <a key={e_services[5].id} href={`services/${e_services[5].libelle}`} className="" aria-current="true" style={{ fontSize: 10, alignContent: 'center' }}>
                                        {e_services[5].libelle.slice(0, 80)}
                                    </a>
                                </div>}
                            </div>
                        }
                       {e_services && e_services.length > 5 && <p className="showmoreEservice"><a href='/services_usuels'>Voir plus <i className="fas fa-caret-down"></i></a></p>}
                    </div>
                </div>
                <div className="card mb-2">
                    <h6 className="card-title textPrimary border-bottom p-2">Les e-Services</h6>
                    <div className="card-body">
                        {
                            mecef.length > 0 && <div className="row">
                                <div className="col-md-4 eservice-custom">
                                    <i class="fas fa-user"></i><br />
                                    <a key={mecef[0].id} href={`services/${mecef[0].libelle}`} className="" aria-current="true" style={{ fontSize: 10, alignContent: 'center' }}>
                                        {mecef[0].libelle}
                                    </a>
                                </div>
                                {mecef.length > 1 && <div className="col-md-4 eservice-custom">
                                    <i class="fas fa-user"></i><br />
                                    <a key={mecef[1].id} href={`services/${mecef[1].libelle}`} className="" aria-current="true" style={{ fontSize: 10, alignContent: 'center' }}>
                                        {mecef[1].libelle}
                                    </a>
                                </div>}
                                {mecef.length > 2 && <div className="col-md-4 eservice-custom">
                                    <i class="fas fa-user"></i><br />
                                    <a key={mecef[2].id} href={`services/${mecef[2].libelle}`} className="" aria-current="true" style={{ fontSize: 10, alignContent: 'center' }}>
                                        {mecef[2].libelle}
                                    </a>
                                </div>}
                            </div>
                        }

                       {mecef && mecef.length > 2 && <p className="showmoreEservice"><a href='/services_usuels'>Voir plus <i className="fas fa-caret-down"></i></a></p> }
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Menu;
