import React, { createContext, useEffect, useState } from 'react';
import { getResource } from '../../services/api';
import Footer from './footer';
import Header from './header';
import Loading from './loading';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from '../home';
import Page from '../page';
import Documents from '../document';
import Centre from '../centre';
import Publications from '../publications';
import PublicationsActualites from '../publications/actualites';
import PublicationsAvisCommuniques from '../publications/avis_communiques';
import Faq from '../faq';
import Recherche from '../recherche';
import Statistique from '../statistique';
import MenuCenter from '../menuCenter';
import RechercheIfu from '../search_ifu';
import ActualiteItem from '../publication/actualite_item';
import CommuniqueItem from '../publication/communique_item';
import Reformes from '../publications/reformes';
import PlanSite from '../page/plan_site';
import ServicesUsuels from '../services_usuels';
import Echeances from '../echeance_fiscales';
import ProjetItem from '../projets/projet_item';
import PublicationCategorie from '../projets/categorie';
import AppelOffre from '../appels_offre';
import LeMinistre from '../ministere/le_ministre';
import Technique from '../ministere/technique';
import Cabinet from '../ministere/cabinet';
import Departementale from '../ministere/departementale';
import SousMenusMinistere from '../ministere/sous-menus';
import CatalogueItem from '../services_usuels/catalogue_item';
import PublicationsVideos from '../publications/videos';
import Direction from '../ministere/direction';
import Directions from '../ministere/directions';
import Contact from '../contact';

export const AppContext = createContext()



const MainLayout = (props) => {
    const [layout, setLayout] = useState(null)
    const [appState, setAppState] = useState(INITIAL_APP_STATE)


    useEffect(() => {
        getResource(`layout`).then(res => {
            setLayout(res.data)
        })
    }, [])
    return (
        <AppContext.Provider value={{ appState, setAppState }}>

            <Router>

                {!layout && <Loading />}
                {layout &&
                    <div>
                        <Header layout={layout} />
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route path="/content/:parent/:slug" component={Page} />
                            <Route path="/content/:slug" component={Page} />
                            <Route path="/documentations/:parent/:slug" component={Documents} />
                            <Route path="/documentations_thematiques/:parent/:slug" component={MenuCenter} />
                            <Route path="/publications/:parent/:slug" component={Publications} />
                            <Route path="/centres/:localite" component={Centre} />
                            <Route path="/centres" component={Centre} />
                            <Route path="/actualites/:slug" component={ActualiteItem} />
                            <Route path="/actualites" component={PublicationsActualites} />
                            <Route path="/projets/categorie/:catProj" component={PublicationCategorie} />
                            <Route path="/projets/:slug" component={ProjetItem} />
                            <Route path="/reformes/:slug" component={CommuniqueItem} />
                            <Route path="/reformes" component={Reformes} />
                            <Route path="/communiques/:slug" component={CommuniqueItem} />
                            <Route path="/communiques" component={PublicationsAvisCommuniques} />
                            <Route path="/foire_aux_questions/:slug/:question" component={Faq} />
                            <Route path="/foire_aux_questions/:slug" component={Faq} />
                            <Route path="/foire_aux_questions" component={Faq} />
                            <Route path="/statistique_reforme" component={Statistique} />
                            <Route path="/messager_impot" component={Statistique} />
                            <Route path="/recherche/:query" component={Recherche} />
                            <Route path="/recherche_ifu" component={RechercheIfu} />
                            <Route path="/page/plansite" component={PlanSite} />
                            <Route path="/services_usuels" component={ServicesUsuels} />
                            <Route path="/echeance_fiscales" component={Echeances} />
                            <Route path="/appels-d'offres" component={AppelOffre} />
                            <Route path="/le-ministre" component={LeMinistre} />
                            <Route path="/directions/:name/:libUnique" component={Direction} />
                            <Route path="/direction/:name/:libUnique" component={Directions} />
                            <Route path="/direction-techniques" component={Technique} />
                            <Route path="/direction-cabinets" component={Cabinet} />
                            <Route path="/direction-departementales" component={Departementale} />
                            <Route path="/ministere/sous-menus" component={SousMenusMinistere} />
                            <Route path="/services/:slug" component={CatalogueItem} />
                            <Route path="/videos" component={PublicationsVideos} />
                            <Route path="/contactez-nous" component={Contact} />

                        </Switch>
                        <Footer layout={layout} />
                    </div>}

            </Router>

        </AppContext.Provider>



    );
}

export default MainLayout;
const INITIAL_APP_STATE = { parent_menus: null }