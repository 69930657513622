export const urlGenerator = menu => {
    const url = "#"
    const parent = menu.parent ? menu.parent.id ? menu.parent.id.split("/") : menu.parent.split("/") : null

    if (menu.name == "Le Ministre") {
        return `/le-ministre`
    }

    if (menu.page) {
        if (!menu.parent)
            return `/content/${menu.page.slug}`
        else {
            return `/content/${parent && parent[3]}/${menu.page.slug}`
        }

    }

    if (menu.module) {

        switch (menu.module.template.libUnique) {
            case 'ministere':
                return `/directions/${menu.name}/${menu.module.libUnique}`
                break;
            case 'document':
                return `/documentations/${parent && parent[3]}/${menu.module.libUnique}`
                break;

            case 'documentation':
                return `/documentations/${parent && parent[3]}/${menu.module.libUnique}`
                break;

            case 'menu-au-centres':
                return `/documentations_thematiques/${menu.id}/${menu.name}`
                break;

            case 'actus':
                return `/actualites`
                break;
            case 'avis-et-communiques':
                return `/communiques`
                break;
            case 'projet':
                return `/projets/categorie/${menu.name}`
                break;

            default:
                break;
        }
        return `/module/${menu.module.id}`
    }
    if (menu.url) {
        return menu.url
    }

    return url
}
