import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getResource } from '../../services/api';
import { AppContext } from '../layout'

import { ITEM_PER_PAGE } from '../../constants';

function Recherche() {
    let { query } = useParams();

    const [results, setResults] = useState([]);
    const [search, setSearch] = useState(null);
    const [openSearch, setOpenSearch] = useState(false);
    // const [currentPage, setCurrentPage] = useState(1)
    // const [numberPage, setNumberPage] = useState([])


    useEffect(() => {
        loadData()
    }, [search]);

    useEffect(() => {
        loadData()
    }, []);

    const loadData = () => {
        getResource(`search?q=${query}`).then(res => {
            setResults(res.data)
            // let array_pages = Math.ceil(parseInt(res.data['hydra:totalItems']) / parseInt(ITEM_PER_PAGE))
            // array_pages = Array.from(Array(array_pages).keys())
            // setNumberPage(array_pages);
        })
    }

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">Recherche {query}</h2>
                    <div className="bread-crumb">
                        <div className="container text-uppercase">
                            <span><a href="/">ACCUEIL</a> -</span>
                            <span>Résultats  {query} </span>
                        </div>
                    </div>
                </div>


            </div>

            <div className="container mb-3 mt-3">
                <div className="searchContainer dgishadow rounded">

                    <div className={`search actulite ${openSearch && 'open'}`} onClick={() => { setOpenSearch(true) }}>

                        <div className="icon" title="Clique pour Recherchez">
                            <span>
                                <svg viewBox="0 0 40 40">
                                    <path d="M3,3 L37,37"></path>
                                </svg>
                            </span>
                        </div>
                        <div className="field">
                            <input type="text" name="search" value={search} placeholder="Que recherchez-vous ?" onChange={(event) => setSearch(event.target.value)} />
                        </div>
                    </div>
                    <div>
                        {/* <nav className="courses-pagination pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav> */}


                    </div>
                </div>
                <div className="row mt-3">
                    <div className="list-group">

                        {results.map(act => (
                            <a key={act.id} href={`/communiques/${act.slug}`} className="list-group-item list-group-item-action" aria-current="true">
                                <div className="d-flex w-100 justify-content-between">
                                    <h5 className="commiqueTitle">{act.titre}</h5>
                                    {/* <p className="comminqueDate"><i className="fas fa-calendar-day"></i> <br />12 Juin <br /> <span
                                        className="annee">2021</span></p> */}
                                </div>
                            </a>

                        ))}
                    </div>


                </div>
            </div>


        </div>
    )
}

export default Recherche;