import React from 'react';

function PoleItem() {

    return (
        <div className="card mb-3 mt-3 dgishadow">
            <div className="row g-0 les-centre">
                <div className="card-body">
                    <h6 className="card-title"><a href="" className="textPrimary">Libellé du Pole de DEV</a>
                        {/* <span className="badge bg-secondary">{centre.departement.libelle}</span> */}
                    </h6>
                    <div className="border-top ">

                        <div>
                            <p className="text-muted xs-font">Responsable</p>
                            <small className=""><b>Le responsable</b> </small>
                        </div>

                    </div>
                    <div className="d-flex justify-content-between ">
                        <div>
                            <span className="xs-font text-uppercase">Contact : +00 00 00 00 <br /></span>
                            {/* {centre.serviceContacts && centre.serviceContacts.map(cs => (<span className="xs-font text-uppercase" key={cs.id}>{cs.service.libelle} ( {cs.typeContact.libelle} : {cs.valeur} ) <br /></span>))} */}
                        </div>

                        {/* <div><span className="xs-font">xxx@impots.bj</span></div>
                <div></div>
                <div></div> */}

                        <p className="card-text"></p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PoleItem;