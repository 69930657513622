export const sliderConfig = {
    dots: false,
    infinite: true,
    arrows: true,
    autoplay: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // initialSlide: 0,
    prevArrow: <button className='slick-prev'><i className='fas fa-long-arrow-alt-left'></i></button>,
    nextArrow: <button className='slick-next'><i className='fas fa-long-arrow-alt-right'></i></button>,
    focusOnSelect: true,
    autoplaySpeed: 8000,
    draggable: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

export const ITEM_PER_PAGE = 10

export const GOOGLE_MAP_API_KEY = 'AIzaSyBI-X6WnE18DvVX1yTvBA3mDnm1W146uk8'