import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../lib/loader';
import { getResource } from '../../services/api';
import { AppContext } from '../layout';

function Technique() {
    const [page, setPage] = useState('');
    const [directions, setDirections] = useState('');
    const [loading, setLoading] = useState(false);
    let { slug, parent } = useParams();
    const { appState, setAppState } = useContext(AppContext)


    useEffect(() => {
        setLoading(true)
        getResource(`techniques`).then(res => {
            setDirections(res.data['hydra:member'])
            setPage(res.data['hydra:member'][0])
            setAppState({ ...appState, parent_menu: parent })
            setLoading(false)
        })
    }, []);

    const getDirection = (libelle) => {
        setPage('')
        setLoading(true)
        getResource(`techniques?libelle=${libelle}`).then(res => {
            setPage(res.data['hydra:member'][0])
            setLoading(false)
        })
    }

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">Directions Techniques</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="#">ACCUEIL</a> -</span>
                            <span>Ministère -</span>
                            {/* <span>Directions Techniques</span> */}
                        </div>
                    </div>
                </div>


            </div>
            {
                loading == true ? <Loader /> :
                    <div className="container mb-3 mt-3">

                        {page && <div className="row" style={{ position: 'relative' }}>

                            {directions && <div className="col-md-3">
                                <ul className="list-group dgi-list-group2">
                                    {
                                        directions.map(dir => (
                                            <button className='btn m-2 projet_content_key1 text-center' onClick={() => getDirection(dir.libelle)}>
                                                <li className={`list-group-item ac ${page.id == dir.id ? `active` : ``}`}>{dir.libelle}</li>
                                            </button>

                                        ))
                                    }
                                </ul>
                            </div>}

                            <div className="col-sm-12 col-md-9 p-4 card dgishadow" style={{ minHeight: 400 }}>
                                <div className="projet_titre">
                                    <h4>{page.libelle}</h4>
                                </div>
                                <div className="w-25 divider">
                                    &nbsp;
                                </div>
                                <div className="dgishadow p-2">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-4 dgishadow p-2" style={{ position: 'relative', zIndex: 3 }} >
                                            {page && page.media != null ? <img src={`${process.env.REACT_APP_API_BASE_URL}/${page.media.contentUrl}`} alt={page.directeur} className="img-fluid" style={{ height: '200px', width: '300px' }} /> : <img src={'/img/noimage.jpg'} alt={page.directeur} className="img-fluid" style={{ height: '200px', width: '300px' }} />}
                                        </div>
                                        <div className="col-sm-12 col-md-8 dgishadow">
                                            <div className="d-flex mb-3" id="alertinfo_top" >
                                                <div className="p-1 pe-2 bd-highlight  textG titre-head1">
                                                    <span className="">Directeur </span>
                                                </div>

                                                <div className="projet_content_key1 p-2">{page.directeur}</div>
                                            </div>
                                            <div className="d-flex mb-3 content-divider" >
                                                <div className="p-1 pe-2 bd-highlight textG titre-head1">
                                                    <span className="">Dénomination </span>
                                                </div>

                                                <div className="projet_content_key1 p-2">{page.denomination}</div>
                                            </div>
                                            <div className="d-flex mb-3 content-divider" >
                                                <div className="p-1 pe-2 bd-highlight textG titre-head1">
                                                    <span className="">Activités fares </span>
                                                </div>

                                                <div className="projet_content_key1 p-2">{page.activite}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">

                                    </div>
                                    <div className="row">

                                    </div> */}
                                </div>
                                <div className="pt-4">
                                    <div className="d-flex" >
                                        <div className="p-1 pe-2 bd-highlight textG titre-head1">
                                            <span className="">Mission et Attributions </span>
                                        </div>
                                    </div>
                                    <div className="projet_key1 p-2 pt-2" dangerouslySetInnerHTML={{ __html: `${page.mission}` }}></div>
                                </div> &nbsp;
                                <div className="dgishadow p-2">
                                    <div className="row pt-2">
                                        <div className="col-sm-12 col-md-6">
                                            <div className="projet_content_key1"><span className="projet_key1">Contact:</span>{"  "} {page.contact}</div>
                                        </div>
                                        <div className="col-sm-12 col-md-6">
                                            <div className="projet_content_key1"><span className="projet_key1">Email:</span>{"  "} {page.email}</div>
                                        </div>
                                    </div>
                                    <div className="row pt-3">
                                        <div className="projet_content_key1"><span className="projet_key1">Site web:</span>{"  "}
                                            <a href={page.siteWeb} target='_blank'> {page.siteWeb}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
            }
        </div>
    )
}

export default Technique;