import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../lib/loader';
import { urlGenerator } from '../../lib/url';
import { getResource } from '../../services/api';
import { AppContext } from '../layout';
import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    TelegramShareButton,
} from "react-share";

function ProjetItem() {
    const [publication, setPublication] = useState(null);
    const [others, setOthers] = useState(null);
    let { slug, parent } = useParams();
    const { appState, setAppState } = useContext(AppContext)
    const [dateDebut, setDateDebut] = useState(null)
    const [dateFin, setDateFin] = useState(null)
    let stringDateDebut, stringDateFin;


    useEffect(() => {
        getResource(`projet_programmes`).then(res => {
            // console.log(res.data['hydra:member'])
            setOthers(res.data['hydra:member'])
        })
        getResource(`projet_programmes?slug=${slug}`).then(res => {
            setPublication(res.data['hydra:member'][0])
            setAppState({ ...appState, parent_menu: parent })
            stringDateDebut = res.data['hydra:member'][0].dateDebut.toString()
            stringDateFin = res.data['hydra:member'][0].dateFin.toString()
            setDateDebut(new Date(stringDateDebut))
            setDateFin(new Date(stringDateFin))
        })


    }, []);

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">{publication && publication.titre}</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="/">ACCUEIL</a> -</span>
                            <span><a href="/">Projets et Programmes</a> -</span>
                            <span>{publication && publication.titre}</span>
                        </div>
                    </div>
                </div>
            </div>
            {publication == null ? <Loader /> :
                <div className="container mb-3 mt-3">
                    <div className="row" style={{ position: 'relative' }}>
                        <div className="col-sm-12 col-md-4">
                            <div className="card dgishadow" style={{ position: 'relative', zIndex: 3 }} >
                                {publication && publication.media && <img src={`${process.env.REACT_APP_API_BASE_URL}/${publication.media.contentUrl}`} alt={publication.titre} className="img-fluid" style={{ height: '200px' }} />}
                            </div>
                            <div className="d-flex mt-5" >
                                <div className="p-1 pe-2 bd-highlight bckColor text-white titre-head3">
                                    <span className=" ">Autres projets</span>
                                </div>
                            </div>
                            {
                                others && others.filter(oth => oth.slug != slug).map(p => (
                                    <a href={`/projets/${p.slug}`} className="mb-3">
                                        <div className="card dgishadow" style={{ position: 'relative', zIndex: 3 }} >
                                            {p.media && <img src={`${process.env.REACT_APP_API_BASE_URL}/${p.media.contentUrl}`} alt={p.titre} className="img-fluid" style={{ height: '200px' }} />}
                                        </div>
                                    </a>
                                ))
                            }
                        </div>
                        {publication && <div className="col-sm-12 col-md-8 border-bottom-3">
                            <div className="card dgishadow">

                                <div className="d-flex justify-content-between bckColorDark p-3 text-white">
                                    <div>
                                        Partagez sur:
                                        <FacebookShareButton className="secdText m-2" title={publication && publication.titre} url={`${process.env.REACT_APP_API_BASE_URL}/${publication.media.contentUrl}`}>
                                            <i
                                                className="fab fa-facebook"></i>
                                        </FacebookShareButton>
                                        <WhatsappShareButton className="secdText m-2" title={publication && publication.titre} url={`${process.env.REACT_APP_API_BASE_URL}/${publication.media.contentUrl}`}>
                                            <i
                                                className="fab fa-whatsapp"></i>
                                        </WhatsappShareButton>
                                        <TelegramShareButton className="secdText m-2" title={publication && publication.titre} url={`${process.env.REACT_APP_API_BASE_URL}/${publication.media.contentUrl}`}>
                                            <i
                                                className="fab fa-telegram"></i>
                                        </TelegramShareButton>
                                        <TwitterShareButton className="secdText m-2" title={publication && publication.titre} url={`${process.env.REACT_APP_API_BASE_URL}/${publication.media.contentUrl}`}>
                                            <i
                                                className="fab fa-twitter"></i>
                                        </TwitterShareButton>
                                    </div>
                                </div>

                                {/* {publication && publication.media && <img src={`${process.env.REACT_APP_API_BASE_URL}/${publication.media.contentUrl}`} alt={publication.titre} className="img-fluid" style={{ height: '300px' }} />} */}
                                <div className="projet_titre">
                                    <h3>{publication && publication.titre}</h3>
                                </div>
                                <div className="w-25 divider">
                                    &nbsp;
                                </div>
                                <div className="page-content projet_content"
                                // dangerouslySetInnerHTML={{ __html: `${publication && publication.contenuPublication}` }}
                                >
                                    <div className="dgishadow p-2 m-2">
                                        <div className="row secondaryMenu2">
                                            <div className="col-sm-12 col-md-7">
                                                <div className="projet_content_key1"><span className="projet_key1">SITE WEB:</span>{"  "}<a href={publication.siteWeb}>{publication.siteWeb}</a></div>
                                            </div>
                                            <div className="col-sm-12 col-md-5">
                                                <div className="projet_content_key1"><span className="projet_key1">TELEPHONE:</span>{"  "} {publication.tel}</div>
                                            </div>
                                        </div>
                                        <div className="row secondaryMenu2">
                                            <div className="col-sm-12 col-md-7">
                                                <div className="projet_content_key1"><span className="projet_key1">DEBUT DU PROJET:</span>{"  "} {dateDebut != null && (dateDebut.getDate() + 1) + "-" + (dateDebut.getMonth() + 1) + "-" + dateDebut.getFullYear()}</div>
                                            </div>
                                            <div className="col-sm-12 col-md-5">
                                                <div className="projet_content_key1"><span className="projet_key1">FIN DU PROJET:</span>{"  "} {dateFin != null && (dateFin.getDate() + 1) + "-" + (dateFin.getMonth() + 1) + "-" + dateFin.getFullYear()}</div>
                                            </div>
                                        </div>
                                        <div className="row secondaryMenu2 mt-2">
                                            <div className="col-sm-12 col-md-3">
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="projet_content_key1"><span className="projet_key1">COORDONNATEUR:</span>{"  "} {publication.coordonnateur}</div>
                                            </div>
                                            <div className="col-sm-12 col-md-3">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dgishadow m-2 mt-2 p-2">
                                        <div className="d-flex" >
                                            <div className="p-1 pe-2 bd-highlight bckColor text-white titre-head3">
                                                <span className=" ">FILIERES CIBLEES</span>
                                            </div>
                                        </div>
                                        <div className="projet_key1" dangerouslySetInnerHTML={{ __html: `${publication.filiere}` }}></div>
                                    </div>
                                    <div className="m-2">
                                        <div className="d-flex" >
                                            <div className="p-1 pe-2 bd-highlight bckColor text-white titre-head3">
                                                <span className=" ">ZONES DE COUVERTURE</span>
                                            </div>
                                        </div>
                                        <div className="projet_key1 dgishadow p-2" dangerouslySetInnerHTML={{ __html: `${publication.zones}` }}></div>
                                    </div>
                                    <div className="m-2">
                                        <div className="d-flex" >
                                            <div className="p-1 pe-2 bd-highlight bckColor text-white titre-head3">
                                                <span className=" ">OBJECTIFS SPECIFIDUES</span>
                                            </div>
                                        </div>
                                        <div className="projet_key1 dgishadow p-2" dangerouslySetInnerHTML={{ __html: `${publication.objectifs}` }}></div>
                                    </div>
                                    <div className="m-2">
                                        <div className="d-flex" >
                                            <div className="p-1 pe-2 bd-highlight bckColor text-white titre-head3">
                                                <span className=" ">INDICATEURS DE RESULTATS</span>
                                            </div>
                                        </div>
                                        <div className="projet_key1 dgishadow p-2" dangerouslySetInnerHTML={{ __html: `${publication.resultats}` }}></div>
                                    </div>
                                    <div className="m-2">
                                        <div className="d-flex" >
                                            <div className="p-1 pe-2 bd-highlight bckColor text-white titre-head3">
                                                <span className=" ">INDICATEURS D'IMPACTS</span>
                                            </div>
                                        </div>
                                        <div className="projet_key1 dgishadow p-2">{publication.impacts}</div>
                                    </div>
                                    <div className="dgishadow mt-2 p-2">
                                        <div>
                                            <div className="d-flex" >
                                                <div className="p-1 pe-2 bd-highlight bckColor text-white titre-head3">
                                                    <span className=" ">COUT TOTAL DU PROJET</span>
                                                </div>
                                            </div>
                                            <div className="projet_key1 pb-2">{publication.cout}</div>
                                        </div>
                                        <div className="content-divider pt-3 pb-2">
                                            <div className="d-flex" >
                                                <div className="p-1 pe-2 bd-highlight bckColor text-white titre-head3">
                                                    <span className=" ">SOURCES DE FINANCEMENT</span>
                                                </div>
                                            </div>
                                            <div className="projet_key1">{publication.sourceFinance}</div>
                                        </div>
                                        <div className="content-divider pt-3">
                                            <div className="d-flex" >
                                                <div className="p-1 pe-2 bd-highlight bckColor text-white titre-head">
                                                    <span className=" ">LOCALISATION</span>
                                                </div>
                                            </div>
                                            <div className="projet_key1">{publication.localisation}</div>
                                        </div> &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>

                </div>
            }
        </div>
    )
}

export default ProjetItem;