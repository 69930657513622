import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { getResource } from '../../services/api';

const CountNumber = ({ home }) => {
    const [compteur, setCompteurs] = useState([]);

    useEffect(() => {
        getResource(`compteurs`).then(res => {
            setCompteurs(res.data['hydra:member'])
        })
    }, []);

    function animate(obj, initVal, lastVal, duration) {

        let startTime = null;

        //get the current timestamp and assign it to the currentTime variable
        let currentTime = Date.now();

        //pass the current timestamp to the step function
        const step = (currentTime) => {

            //if the start time is null, assign the current time to startTime
            if (!startTime) {
                startTime = currentTime;
            }

            //calculate the value to be used in calculating the number to be displayed
            const progress = Math.min((currentTime - startTime) / duration, 1);

            //calculate what to be displayed using the value gotten above
            obj.innerHTML = Math.floor(progress * (lastVal - initVal) + initVal);

            //checking to make sure the counter does not exceed the last value (lastVal)
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
            else {
                window.cancelAnimationFrame(window.requestAnimationFrame(step));
            }
        };

        //start animating
        window.requestAnimationFrame(step);
    }

    let text1 = document.getElementById('0101');
    let text2 = document.getElementById('0102');
    let text3 = document.getElementById('0103');
    let text4 = document.getElementById('0104');

    const load = () => {
        if (text1 != null) {
            const n = new Intl.NumberFormat('fr-FR').format(compteur[0].nombre) 
            compteur.length > 0 && 
            animate(text1, 0,  (compteur[0].nombre).toLocaleString()  , 5000);
            animate(text2, 0, compteur[1].nombre, 5000);
            animate(text3, 0, compteur[2].nombre, 5000);
            animate(text4, 0, compteur[3].nombre, 5000);
        }
    }

    return (
        <div id="countNumber" onload={load()}>
            {
                compteur.length > 0 && <div className="">
                    <div className="row fw-bold text-center titleCounter">
                        <div className="col-md-3 stats-item1">
                            <a id='0101' style={{ color: "#fff" }} href="#">0</a> {compteur[0].libelle}
                        </div>
                        <div className="col-md-3 stats-item2">
                            <a id='0102' style={{ color: "#fff" }} href="#">876</a> {compteur[1].libelle}
                        </div>
                        <div className="col-md-3 stats-item3">
                            <a id='0103' style={{ color: "#fff" }} href="#">877</a> {compteur[2].libelle}
                        </div>
                        <div className="col-md-3 stats-item4">
                            <a id='0104' style={{ color: "#fff" }} href="#">12</a> {compteur[3].libelle}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default CountNumber;