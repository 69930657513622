import './App.css';
import MainLayout from './components/layout';
import Home from './components/home';
import React from 'react';
import ReactGA from 'react-ga'

function App() {

  React.useEffect(() => {
    ReactGA.initialize('UA-200273952-3')
    ReactGA.pageview(window.location.pathname + window.location.search)

  }, [window.location]);
  return (
    <MainLayout>
      <Home />
    </MainLayout>
  );
}

export default App;
