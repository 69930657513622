import React, { useState, useEffect } from 'react';
import youtube from "../../services/youtube";
import moment from 'moment'

import FilAriane from '../layout/ariane';
import YoutubePlay from '../home/youtubePlay';
moment.locale('fr')

function PublicationsVideos() {

    const [nextToken, setNextToken] = useState('')
    const [prevToken, setPrevToken] = useState('')
    const [moduleVideos, setModuleVideos] = useState([])


    useEffect(() => {
        getPrevToken()
    }, []);

    // const loadData = () => {
    //     setIsActive(true)
    //     getResource(`publications?categorie.libUnique=actus&itemsPerPage=9&page=${currentPage}${search ? `&titre=${search}` : ''}`).then(res => {
    //         setPublications(res.data['hydra:member'])
    //         setIsActive(false)
    //         let array_pages = Math.ceil(parseInt(res.data['hydra:totalItems']) / parseInt(ITEM_PER_PAGE))
    //         array_pages = Array.from(Array(array_pages).keys())
    //         setNumberPage(array_pages);
    //     })
    // }

    const getNextToken = () => {
        youtube.get('/search', {
            params: {
                pageToken: nextToken
            }
        }).then(res => {
            setModuleVideos(res.data.items);
            setNextToken(res.data.nextPageToken)
        })
    }

    const getPrevToken = () => {
        youtube.get('/search', {
            params: {
                pageToken: prevToken
            }
        }).then(res => {
            setModuleVideos(res.data.items);
            setPrevToken(res.data.prevPageToken)
        })
    }

    const ariane = {
        primary: { title: 'ACCUEIL', url: '/' },
        secondary: { title: 'VIDEOS' }
    }

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">Vidéos</h2>
                    <FilAriane ariane={ariane} />
                </div>


            </div>

            <div className="container mb-3 mt-3">
                <div className="searchContainer dgishadow rounded">

                    <div className="x-scroll">
                        <nav className="courses-pagination pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a onClick={() => getPrevToken()} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>

                                <li className="page-item">
                                    <a onClick={() => getNextToken()} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>


                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-12" style={{ minHeight: "500px" }}>
                        <ul className="gallery caption-1 mt-2">
                            {
                                moduleVideos.map(module => (
                                    <li>
                                        <YoutubePlay vidId={`https://www.youtube.com/watch?v=${module.id.videoId}`} />
                                        {/* <figure>
                                            <a href={`https://www.youtube.com/watch?v=${module.id.videoId}`}>
                                                <img src={module.snippet.thumbnails.medium.url}
                                                    alt="" />
                                                <figcaption>
                                                    <h1>{module.snippet.title}</h1>
                                                    <p>Par: {module.snippet.channelTitle}</p>
                                                </figcaption>
                                            </a>
                                        </figure> */}
                                    </li>))
                            }

                        </ul>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default PublicationsVideos;