import React, { useState, useEffect } from 'react';
import { getResource } from '../../services/api';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { GOOGLE_MAP_API_KEY } from '../../constants';
import Geocode from "react-geocode";
import CentreItem from './item';
import { useParams } from 'react-router-dom';

Geocode.setApiKey(GOOGLE_MAP_API_KEY);

Geocode.setLanguage("fr");
Geocode.setRegion("bj");

const wrapperStyle = {
    position: 'relative',
    width: '100%',
    height: '700px',
    paddingTop: '50%',
};

const innerWrapperStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
};

const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%'
};

const mapStyle = [
    {
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }]
    },
]




function Centre({ google }) {
    const [centres, setCentres] = useState([]);
    const [departements, setDepartements] = useState([]);
    const [userPosition, setUserPosition] = useState(null);
    const [userAddress, setUserAddress] = useState(null);
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [activeMarker, setActiveMarker] = useState({});
    const [activeMarkerData, setActiveMarkerData] = useState(null);
    let { localite } = useParams();
    const [localisation, setLocalisation] = useState(localite);
    const timer = ms => new Promise(res => setTimeout(res, ms))




    const [filter, setFilter] = useState({ departement: null });


    useEffect(() => {
        getCurrentLocation()
        loadDepartements()
        load()
    }, []);

    useEffect(() => {
        if (userAddress ) {
           loadCentres(userAddress)
        }
    }, [userAddress]);

    const loadCentres = (address=null) => {
        getResource(`centres?pagination=0${localisation ?`&localite.libUnique=${localisation.toLowerCase()}`:address?`&localite.libUnique=${address}`:''}`).then(res => {
            setCentres(res.data['hydra:member'])
        })
    }


    const loadDepartements = () => {
        getResource(`localites?nivLocalite=1&pagination=0`).then(res => {
            setDepartements(res.data['hydra:member'])
        })
    }

    const getCurrentLocation = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setUserPosition(position)
            getCurrentAdress(position)
        });
    }

    const getCurrentAdress = (position) => {

        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            (response) => {
                const address = response.results[0].formatted_address;
                setUserAddress(address)
            },
            (error) => {
                console.error(error);
            }
        );
    }


    function onMarkerClick(props, marker, e, data) {
        setActiveMarker(marker);
        setActiveMarkerData(data);
        setShowingInfoWindow(true);
    }


    const onInfoWindowClose = () => { }

    async function load () { 
        for (var i = 0; i < 3; i++) {
          await timer(2000); 
          setShowMap(true)

        }
      }
      

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info pt-3">
                    <h2 className="text-center text-white">Centres proches  de ma position. {localisation}</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="/">ACCUEIL</a> -</span>
                            <span>{localite&&<a href="/centres">CENTRES </a>}</span>
                            <span>{!localite&& 'CENTRES - '}</span>

                            {localite && <span> - {localite}</span>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mb-3 mt-3">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-2 border-end">
                        <h6 className="secdText border-bottom p-2">LES DEPARTEMENTS</h6>
                        <ul className="faq-cat">
                            {
                                departements.map(dep => (<li><a key={dep.id} href={`/centres/${dep.libelle}`} className="textPrimary">{dep.libelle}</a></li>))
                            }

                        </ul>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 listdescentre">
                        <h6 className="secdText border-bottom p-2 text-uppercase">Centres les plus proches</h6>
                        {
                            centres.map(centre => (
                                <CentreItem centre={centre}/>

                            ))
                        }


                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <h6 className="secdText border-bottom p-2 text-uppercase">Localiser sur la carte</h6>
                        <div className="card p-2 mt-2 dgishadow">
                            <div style={{ height: '100vh', width: '100%' }}>

                               {showMap && <Map
                                    google={google}
                                    zoom={11}
                                    initialCenter={
                                        {
                                            lat: userPosition && !localite ? userPosition.coords.latitude : centres.length>0?centres[0].latitude:6.37,
                                            lng: userPosition&& !localite ? userPosition.coords.longitude :centres.length>0?centres[0].longitude: 2.40
                                        }
                                    }
                                >

                                    {
                                        centres.map(centre => (
                                            <Marker
                                                onClick={(props, marker, e) => onMarkerClick(props, marker, e, centre)}

                                                position={{
                                                    lat: centre.latitude,
                                                    lng: centre.longitude
                                                }}
                                                key={centre.id}
                                                icon={{
                                                    url: "/img/marker.png",
                                                    anchor: new google.maps.Point(32, 32),
                                                    scaledSize: new google.maps.Size(32, 32)
                                                }}
                                                name={'Current location'} />
                                        ))
                                    }



                                    <InfoWindow
                                        google={google}
                                        onClose={onInfoWindowClose}
                                        marker={activeMarker}

                                        visible={showingInfoWindow}>

                                           { activeMarkerData && <h5 className="card-title"><a href="" className="textPrimary">{activeMarkerData.libelle}</a></h5> }
                                    </InfoWindow>
                                </Map> }


                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default GoogleApiWrapper({
    apiKey: (GOOGLE_MAP_API_KEY)
})(Centre)
