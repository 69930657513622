import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getResource } from '../../services/api';
import { AppContext } from '../layout'
import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    TelegramShareButton,
} from "react-share";
import moment from 'moment'
import { ITEM_PER_PAGE } from '../../constants';
import Loader from '../../lib/loader';
moment.locale('fr')
function PublicationsActualites() {

    const [publications, setPublications] = useState([]);
    const [search, setSearch] = useState(null);
    const [openSearch, setOpenSearch] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])


    useEffect(() => {
        loadData()
    }, [search]);

    useEffect(() => {
        loadData()
    }, [currentPage]);

    const loadData = () => {
        setIsActive(true)
        getResource(`publications?categorie.libUnique=actus&itemsPerPage=9&page=${currentPage}${search ? `&titre=${search}` : ''}`).then(res => {
            setPublications(res.data['hydra:member'])
            setIsActive(false)
            let array_pages = Math.ceil(parseInt(res.data['hydra:totalItems']) / parseInt(ITEM_PER_PAGE))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
        })
    }

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">Actualités</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="/">ACCUEIL</a> -</span>
                            <span>ACTUALITÉS</span>
                        </div>
                    </div>
                </div>


            </div>

            <div className="container mb-3 mt-3">
                <div className="searchContainer dgishadow rounded">

                    <div className={`search actulite ${openSearch && 'open'}`} onClick={() => { setOpenSearch(true) }}>

                        <div className="icon" title="Clique pour Recherchez">
                            <span>
                                <svg viewBox="0 0 40 40">
                                    <path d="M3,3 L37,37"></path>
                                </svg>
                            </span>
                        </div>
                        <div className="field">
                            <input type="text" name="search" value={search} placeholder="Que recherchez-vous ?" onChange={(event) => setSearch(event.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    {isActive == true ? <Loader /> : publications.length == 0 ?
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="commiqueTitle text-center">Aucune donnée ne correspond à votre recherche</h5>
                        </div> : publications && publications.map(act => (
                            <div key={act.id} className="actu-items col-sm-12 col-lg-4 mt-3">
                                <div className="single-actu-item">
                                    <div className="actu-image">
                                        <a href={`/actualites/${act.slug}`}>
                                            {act.media && <img src={process.env.REACT_APP_API_BASE_URL + '/' + act.media.contentUrl} alt={act.titre}
                                                className="img-fluid" />}
                                            {!act.media && <img src={'/img/noimage.jpg'} alt={act.titre}
                                                className="img-fluid" />}
                                        </a>
                                        <div className="shareBtn">
                                            <div className="shareBtn-content">
                                                <FacebookShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_BASE_URL}/${`actualites/${act.slug}`}`}>
                                                    <i
                                                        className="fab fa-facebook"></i>
                                                </FacebookShareButton>
                                                <WhatsappShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_BASE_URL}/${`actualites/${act.slug}`}`}>
                                                    <i
                                                        className="fab fa-whatsapp"></i>
                                                </WhatsappShareButton>
                                                {/* <TelegramShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_BASE_URL}/${`actualites/${act.slug}`}`}>
                                                    <i
                                                        className="fab fa-telegram"></i>
                                                </TelegramShareButton> */}
                                                <TwitterShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_BASE_URL}/${`actualites/${act.slug}`}`}>
                                                    <i
                                                        className="fab fa-twitter"></i>
                                                </TwitterShareButton>
                                            </div>
                                            <span className="z-depth-2"><i className="fas fa-share-alt"></i></span>

                                        </div>
                                        <div className="actu-meta">
                                            <p>Publié le {moment(act.createdAt).format('DD MMMM YYYY')}</p>
                                        </div>
                                    </div>
                                    <div className="actu-text">
                                        <a href={`/actualites/${act.slug}`}>
                                            <h3 className="text-uppercase">{act.titre.length > 45 ? act.titre.slice(0, 45) + '...' : act.titre}</h3>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}

                </div>
                <div className="searchContainer dgishadow rounded">

                    <div>
                        <nav className="courses-pagination pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <h3 style={{ color: "#fff" }}>Actualités</h3>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="x-scroll">
                        <nav className="courses-pagination pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>


                    </div>
                </div>
            </div>


        </div>
    )
}

export default PublicationsActualites;