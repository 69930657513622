import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../lib/loader';
import { urlGenerator } from '../../lib/url';
import { getResource } from '../../services/api';
import { AppContext } from '../layout';

function CommuniqueItem() {
    const [publication, setPublication] = useState(null);
    let { slug, parent } = useParams();
    const { appState, setAppState } = useContext(AppContext)


    useEffect(() => {
        getResource(`publications?slug=${slug}`).then(res => {
            setPublication(res.data['hydra:member'][0])
            setAppState({ ...appState, parent_menu: parent })
        })


    }, []);

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">{publication && publication.titre}</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="/">ACCUEIL</a> -</span>
                            <span><a href="/communiques">COMMUNIQUES</a> -</span>
                            <span>{publication && publication.titre}</span>
                        </div>
                    </div>
                </div>


            </div>
            <div className="container mb-3 mt-3">
                <div className="row" style={{ position: 'relative' }}>
                    <div className="col-sm-12 col-md-9 border-bottom-3">
                        <div className="card dgishadow">

                            <div className="d-flex justify-content-between bckColorDark p-3 text-white">
                                <div>
                                    Partagez sur: <a href="" className="secdText"><i className="fab fa-facebook-f"></i></a>
                                    <a href="" className="ps-2 secdText"><i
                                        className="fab fa-twitter"></i></a>
                                </div>
                            </div>

                            {/* {publication && publication.media  && <img src={`/${publication.media.contentUrl}`} alt={publication.titre} className="img-fluid"/>} */}
                            {
                                publication == null ? <Loader /> : <div className="page-content" style={{ position: 'relative' }}
                                    dangerouslySetInnerHTML={{ __html: `${publication && publication.contenuPublication}` }}
                                ></div>
                            }


                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default CommuniqueItem;