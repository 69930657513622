import React, { useEffect } from 'react';
import Marquee from './marquee';
import Menu from './menu';
import $ from 'jquery'
const Header = ({ layout }) => {

    useEffect(() => {

        if (layout.parametres.filter(p => p.libUnique == "alert" && p.valeur == 1)) {
            $('.traducoes').removeClass("traducoeschange")

            $('.navbar_top').addClass('marginTop-navBar')
        }
        if (layout.parametres.filter(p => p.libUnique == "alert" && p.valeur == 0)) {
            $('.traducoes').addClass("traducoeschange")
            $('.navbar_top').removeClass('marginTop-navBar')

        }
        $('.traducoes').removeClass("traducoeschange")

    }, [])

    return (
        <>
            <div className="d-flex alert-zone" id="alertinfo_top" >

                {layout.parametres.filter(p => p.libUnique == "alert" && p.valeur == 1).length > 0 && <Marquee datas={layout.flash_infos} />}
            </div>

            {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid topmenu">
                    <div style={{ height: '40px' }} ></div>

                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav mx-auto secondaryMenu hide4mobile">
                            {layout.liens_cat_header.map(el => (
                                <li key={el.id} className="nav-item">
                                    <a className="nav-link" href={el.url.includes('http') ? el.url : `/${el.url}`}>{el.libelle}</a>
                                </li>
                            ))}


                        </ul>
                        <ul className="navbar-nav ms-auto secondaryMenu">
                            {layout.parametres.filter(p => p.libUnique == "espace_contribuable" && p.valeur == 1).length > 0 && <li className="nav-item"><a href="" className="mon-espace"><i className="fas fa-user-secret"></i>&nbsp;
                                Espace contribuable</a></li>}
                        </ul>

                    </div>
                </div>
            </nav> */}
            <Menu menus={layout.menus} e_services={layout.catalogue_service} mecef={layout.catalogue_service.filter(el => el.type != 'Manuel')} layout={layout} />

        </>
    );
}

export default Header;