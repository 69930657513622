import React, { useState, useEffect } from 'react';
import LinkUrl from '../../lib/link';

function FaqCategorie({ cf, slice , slug}) {

const [faqList, setfaqList] = useState(slice?cf.faqs.slice(0,3):cf.faqs);
    return (
        <>
            <div key={cf.libelle} className="faq-group">
                <h5>{cf.libelle}
                    <LinkUrl to={`/foire_aux_questions/${cf.libUnique}`} className=" cursor btn btn-outline-warning float-end xs-font text-dark btn-sm">Voir tout
                    </LinkUrl>
                </h5>

                <div className="card p-2 col-sm-12 ">
                    <div className="accordion row" id="centeraccordeon">
                        {faqList.map((faq, index) => (
                            <div key={faq.id} className="accordion-item  mt-3 col-sm-12  ">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed text-uppercase dgishadow" type="button" data-bs-toggle="collapse"
                                        data-bs-target={`#faqtop${faq.id}`} aria-expanded="false" aria-controls={`faqtop${index}`}>
                                        <i
                                            className="fas fa-question-circle "></i> {faq.libelleQestion}
                                    </button>
                                </h2>
                                <div id={`faqtop${faq.id}`} className="accordion-collapse collapse" aria-labelledby="headingOne"
                                    data-bs-parent="#centeraccordeon">
                                    <div className="accordion-body border-bottom">
                                        <p className="mb-3 justify-content-around" dangerouslySetInnerHTML={{ __html: faq.reponse }} ></p>

                                    </div>
                                </div>
                            </div>

                        ))}



                    </div>
                </div>


            </div>
        </>
    )
}

export default FaqCategorie;