import React, { useState, useEffect } from 'react';
import LinkUrl from '../../lib/link';
import { getResource } from '../../services/api';

function FaqThemes({ slug }) {
    const [themes, setThemes] = useState([]);

    useEffect(() => {
        loadFaqs()
    }, []);

    const loadFaqs = () => {
        getResource(`categories_faqs`).then(res => {
            setThemes(res.data['hydra:member'])
        })
    }

    return (
        <>
            <h6 className="secdText border-bottom p-2">LES THEMES</h6>

            <ul className="faq-cat">
                <li ><LinkUrl to={`/foire_aux_questions`} className={`cursor  text-uppercase text-bold ${!slug ? 'active textPrimary' : 'textSecondary'}`}>Tous les thèmes</LinkUrl></li>

                {
                    themes && themes.map(cf => (
                        <li key={cf}><LinkUrl to={`/foire_aux_questions/${cf.libUnique}`} className={`cursor  text-uppercase  ${slug == cf.libUnique ? 'active textPrimary' : 'textSecondary'}`}>{cf.libelle}</LinkUrl></li>

                    ))
                }

            </ul>
        </>
    )
}

export default FaqThemes;