import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../lib/loader';
import { urlGenerator } from '../../lib/url';
import { getResource } from '../../services/api';
import { AppContext } from '../layout';
import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    TelegramShareButton,
} from "react-share";

function CatalogueItem() {
    const [publication, setPublication] = useState(null);
    const [others, setOthers] = useState(null);
    let { slug, parent } = useParams();
    const { appState, setAppState } = useContext(AppContext)


    useEffect(() => {
        getResource(`catalogues?libelle=${slug}`).then(res => {
            // console.log(res.data)
            setPublication(res.data['hydra:member'][0])
            setAppState({ ...appState, parent_menu: parent })
        })
        getResource(`catalogues?itemsPerPage=4`).then(res => {
            // console.log(res.data['hydra:member'])
            setOthers(res.data['hydra:member'])
        })
    }, []);

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">{slug}</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="/">ACCUEIL</a> -</span>
                            <span><a href="/services_usuels">Services</a> -</span>
                            <span>{slug}</span>
                        </div>
                    </div>
                </div>
            </div>
            {publication == null ? <Loader /> :
                <div className="container mb-3 mt-3">
                    <div className="row" style={{ position: 'relative' }}>
                        <div className="col-sm-12 col-md-4">
                            <div className="d-flex mt-5" >
                                <div className="p-1 pe-2 bd-highlight bg-dark text-white titre-head3">
                                    <span className=" ">Autres services</span>
                                </div>
                            </div>
                            {
                                others && others.filter(oth => oth.libelle != slug).map(p => (
                                    <a href={`/services/${p.libelle}`} className="mb-3">
                                        <div className="card dgishadow mt-3 p-2" style={{ position: 'relative', zIndex: 3, borderRadius: '10px' }} >
                                            <p>{p.libelle}</p>
                                        </div>
                                    </a>
                                ))
                            }
                        </div>
                        {publication && <div className="col-sm-12 col-md-8 border-bottom-3">
                            <div className="card dgishadow">

                                {/* <div className="d-flex justify-content-between bckColorDark p-3 text-white">
                                    <div>
                                        Partagez sur:
                                        <FacebookShareButton className="secdText m-2" title={publication && publication.libelle} url={`${process.env.REACT_APP_API_BASE_URL}/${publication.media.contentUrl}`}>
                                            <i
                                                className="fab fa-facebook"></i>
                                        </FacebookShareButton>
                                        <WhatsappShareButton className="secdText m-2" title={publication && publication.titre} url={`${process.env.REACT_APP_API_BASE_URL}/${publication.media.contentUrl}`}>
                                            <i
                                                className="fab fa-whatsapp"></i>
                                        </WhatsappShareButton>
                                        <TelegramShareButton className="secdText m-2" title={publication && publication.titre} url={`${process.env.REACT_APP_API_BASE_URL}/${publication.media.contentUrl}`}>
                                            <i
                                                className="fab fa-telegram"></i>
                                        </TelegramShareButton>
                                        <TwitterShareButton className="secdText m-2" title={publication && publication.titre} url={`${process.env.REACT_APP_API_BASE_URL}/${publication.media.contentUrl}`}>
                                            <i
                                                className="fab fa-twitter"></i>
                                        </TwitterShareButton>
                                    </div>
                                </div> */}

                                {/* {publication && publication.media && <img src={`${process.env.REACT_APP_API_BASE_URL}/${publication.media.contentUrl}`} alt={publication.titre} className="img-fluid" style={{ height: '300px' }} />} */}
                                <div className="projet_titre">
                                    <h3>{publication && publication.libelle}</h3>
                                </div>
                                <div className="w-25 divider">
                                    &nbsp;
                                </div>
                                <div className="page-content projet_content"
                                // dangerouslySetInnerHTML={{ __html: `${publication && publication.contenuPublication}` }}
                                >
                                    <div className="dgishadow p-2 m-2">
                                        <div className="row secondaryMenu2">
                                            <div className="col-sm-12 col-md-7">
                                                <div className="projet_content_key1"><span className="projet_key1">SITE WEB:</span>{"  "} {publication.lien}</div>
                                            </div>
                                            <div className="col-sm-12 col-md-5">
                                                <div className="projet_content_key1"><span className="projet_key1">TYPE DE SERVICE:</span>{"  "} {publication.type}</div>
                                            </div>
                                        </div>
                                        <div className="row secondaryMenu2 mt-2">
                                            <div className="col-sm-12 col-md-12">
                                                <div className="projet_content_key1"><span className="projet_key1">Structure Propriétaire:</span>{"  "} {publication.departement.libelle}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dgishadow m-2 mt-2 p-2">
                                        <div className="d-flex" >
                                            <div className="p-1 pe-2 bd-highlight bckColor text-white titre-head3">
                                                <span className=" ">Prix de la Prestation</span>
                                            </div>
                                        </div>
                                        <div className="projet_key1" dangerouslySetInnerHTML={{ __html: `${publication.prix}` }}></div>
                                    </div>
                                    <div className="m-2">
                                        <div className="d-flex" >
                                            <div className="p-1 pe-2 bd-highlight bckColor text-white titre-head3">
                                                <span className=" ">Pièces à fournir</span>
                                            </div>
                                        </div>
                                        <div className="projet_key1 dgishadow p-2" dangerouslySetInnerHTML={{ __html: `${publication.pieces}` }}></div>
                                    </div>
                                    <div className="m-2">
                                        <div className="d-flex" >
                                            <div className="p-1 pe-2 bd-highlight bckColor text-white titre-head3">
                                                <span className=" ">Processus de demande</span>
                                            </div>
                                        </div>
                                        <div className="projet_key1 dgishadow p-2" dangerouslySetInnerHTML={{ __html: `${publication.processus}` }}></div>
                                    </div>
                                    <div className="m-2">
                                        <div className="d-flex" >
                                            <div className="p-1 pe-2 bd-highlight bckColor text-white titre-head3">
                                                <span className=" ">Localisation</span>
                                            </div>
                                        </div>
                                        <div className="projet_key1 dgishadow p-2" dangerouslySetInnerHTML={{ __html: `${publication.localisation}` }}></div>
                                    </div>
                                    <div className="m-2">
                                        <div className="d-flex" >
                                            <div className="p-1 pe-2 bd-highlight bckColor text-white titre-head3">
                                                <span className=" ">Heures d'ouverture</span>
                                            </div>
                                        </div>
                                        <div className="projet_key1 dgishadow p-2" dangerouslySetInnerHTML={{ __html: `${publication.ouverture}` }}></div>
                                    </div>
                                    <div className="m-2">
                                        <div className="d-flex" >
                                            <div className="p-1 pe-2 bd-highlight bckColor text-white titre-head3">
                                                <span className=" ">Personnes à contacter</span>
                                            </div>
                                        </div>
                                        <div className="projet_key1 dgishadow p-2" dangerouslySetInnerHTML={{ __html: `${publication.contact}` }}></div>
                                    </div>
                                    {/* <div className="dgishadow mt-2 p-2">
                                        <div>
                                            <div className="d-flex" >
                                                <div className="p-1 pe-2 bd-highlight bg-dark text-white titre-head3">
                                                    <span className=" ">Personne à contacter</span>
                                                </div>
                                            </div>
                                            <div className="projet_key1 pb-2">{publication.cout}</div>
                                        </div>
                                        <div className="content-divider pt-3 pb-2">
                                            <div className="d-flex" >
                                                <div className="p-1 pe-2 bd-highlight bg-dark text-white titre-head3">
                                                    <span className="hide4mobile">SOURCES DE FINANCEMENT</span>
                                                </div>
                                            </div>
                                            <div className="projet_key1">{publication.sourceFinance}</div>
                                        </div>
                                        <div className="content-divider pt-3">
                                            <div className="d-flex" >
                                                <div className="p-1 pe-2 bd-highlight bg-dark text-white titre-head">
                                                    <span className="hide4mobile">LOCALISATION</span>
                                                </div>
                                            </div>
                                            <div className="projet_key1">{publication.localisation}</div>
                                        </div> &nbsp;
                                    </div> */}
                                </div>
                            </div>
                        </div>}
                    </div>

                </div>
            }
        </div>
    )
}

export default CatalogueItem;