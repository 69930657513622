import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../lib/loader';
import { urlGenerator } from '../../lib/url';
import { getResource } from '../../services/api';
import { AppContext } from '../layout';

function Directions() {
    const [page, setPage] = useState('');
    const [directions, setDirections] = useState('');
    const [loading, setLoading] = useState(false);
    let { libUnique, parent, name } = useParams();
    const { appState, setAppState } = useContext(AppContext)

    useEffect(() => {
        setLoading(true)
        getResource(`directions?module.libUnique=${libUnique}`).then(res => {
            setDirections(res.data['hydra:member'])
            setPage(res.data['hydra:member'][0])
            setAppState({ ...appState, parent_menu: parent })
            setLoading(false)
        })
    }, []);

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">{name}</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="/">ACCUEIL</a> -</span>
                            <span><a href="/">Ministère</a> -</span>
                            <span>{name}</span>
                        </div>
                    </div>
                </div>


            </div>
            {directions == '' ? <Loader /> :
                <div className="container mb-3 mt-3">
                    <div className="row" style={{ position: 'relative' }}>
                        {directions && <div className="col-sm-12 col-md-12 border-bottom-3">
                            <div className="">

                                {/* <div className="d-flex justify-content-between bckColorDark p-3 text-white">
                                    Sous menus du ministère
                                </div> */}
                                <div className="page-content sous-menus_content mt-3" style={{ fontWeight: "bold" }}>
                                    <div className="row">
                                        {directions && directions.map(dir => (
                                            <div className="col-md-3 dim">
                                                <div className="dgishadow smenu-hover m-2">
                                                    <div className="projet_content_key1 text-center p-3"><a href={`/directions/${name}/${dir.module.libUnique}`}>{dir.libelle}</a></div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>

                </div>
            }
        </div>
    )
}

export default Directions;