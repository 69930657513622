import React from 'react';
import Marquee from "react-fast-marquee"

const MarqueeNews = ({ datas }) => {
    return (
        <div className="d-flex alert-zone" id="alertinfo_top" >
            <div className="p-1 pe-2 bd-highlight text-white info-flash" style={{ backgroundColor: '#8B0000' }}>
                <span className="hide4mobile">Info </span><i
                    className="fas fa-bullhorn fa-flip-vertical secdText"></i>
            </div>

            <Marquee pauseOnHover speed={60} gradient={false}>
                {datas.map(marquee => (
                    <a key={marquee.id} href={`/actualites/${marquee.slug}`} className="nodecorator">{marquee.titre}</a>
                ))}
            </Marquee>
        </div>
    );
}

export default MarqueeNews;
