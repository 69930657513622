import React, { useState, useEffect, useContext } from 'react';
import { getResource } from '../../services/api';
import _ from 'lodash'

function PlanSite() {
    const [menus, setMenus] = useState(null)

    useEffect(() => {
        getResource(`layout`).then(res => {
            setMenus(res.data.menus)
                 })
    }, []);

    const urlGenerator = menu => {
        const url = "#"
        const parent = menu.parent != null ? menu.parent.id : null

        if (menu.page) {
            if (!menu.parent)
                return `/content/${menu.page.slug}`
            else {
                return `/content/${parent && parent[3]}/${menu.page.slug}`
            }

        }

        if (menu.module) {

            switch (menu.module.template.libUnique) {
                case 'document':
                    return `/documentations/${parent && parent[3]}/${menu.module.id}`
                    break;

                default:
                    break;
            }
            return `/module/${menu.module.id}`
        }
        if (menu.url) {
            return menu.url
        }

        return url
    }

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">Plan du site</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="">ACCUEIL</a> -</span>
                            {/* <span>{parent_menu} -</span> */}
                            <span>PLAN DU SITE</span>
                        </div>
                    </div>
                </div>


            </div>
            <div className="container mb-3 mt-3">
                <div className="row">
                    {menus && menus.map(m => (
                        m.name == 'TELECHARGEMENTS' && m.enfants &&
                        <div className="col-md-6 pt-4 mt-3 pl-3">
                            <div className="card p-2 dgishadow">
                                <h5 className="card-title">{m.name}</h5>
                                <ul className="planDS">
                                    {(m.enfants && m.enfants.map(m1 => (
                                        <li key={m1.id}><a href={urlGenerator(m1)}>{m1.name}</a></li>
                                    )))}
                                </ul>
                            </div>
                        </div>
                    ))}
                    {menus && menus.map(m => (
                        m.name == 'DOCUMENTATION' && m.enfants &&
                        <div className="col-md-6 pt-4 mt-3">
                            <div className="card p-2 dgishadow">
                                <h5 className="card-title">{m.name}</h5>
                                <ul className="planDS">
                                    {(m.enfants && m.enfants.map(m1 => (
                                        <li key={m1.id}><a href={urlGenerator(m1)}>{m1.name}</a></li>
                                    )))}
                                </ul>
                            </div>
                        </div>
                    ))}
                    {menus && menus.map(m => (
                        m.name == 'STATISTIQUES DES REFORMES' && m.enfants &&
                        <div className="col-md-6 pt-4 mt-3">
                            <div className="card p-2 dgishadow">
                                <h5 className="card-title">{m.name}</h5>
                                <ul className="planDS">
                                    {(m.enfants && m.enfants.map(m1 => (
                                        <li key={m1.id}><a href={urlGenerator(m1)}>{m1.name}</a></li>
                                    )))}
                                </ul>
                            </div>
                        </div>
                    ))}
                    {menus && menus.map(m => (
                        m.name == 'LA DIRECTION' && m.enfants &&
                        <div className="col-md-6 pt-4 mt-3">
                            <div className="card p-2 dgishadow">
                                <h5 className="card-title">{m.name}</h5>
                                <ul className="planDS">
                                    {(m.enfants && m.enfants.map(m1 => (
                                        <li key={m1.id}><a href={urlGenerator(m1)}>{m1.name}</a></li>
                                    )))}
                                </ul>
                            </div>
                        </div>
                    ))}
                    {menus && menus.map(m => (
                        m.enfants && m.enfants.length == 3 &&
                        <div className="col-md-6 pt-4 mt-3">
                            <div className="card p-2 dgishadow">
                                <h5 className="card-title">{m.name}</h5>
                                <ul className="planDS">
                                    {(m.enfants && m.enfants.map(m1 => (
                                        <li key={m1.id}><a href={urlGenerator(m1)}>{m1.name}</a></li>
                                    )))}
                                </ul>
                            </div>
                        </div>
                    ))}
                    {menus && menus.map(m => (
                        m.enfants && m.enfants.length == 2 &&
                        <div className="col-md-6 pt-4 mt-3">
                            <div className="card p-2 dgishadow">
                                <h5 className="card-title">{m.name}</h5>
                                <ul className="planDS">
                                    {(m.enfants && m.enfants.map(m1 => (
                                        <li key={m1.id}><a href={urlGenerator(m1)}>{m1.name}</a></li>
                                    )))}
                                </ul>
                            </div>
                        </div>
                    ))}
                    {menus && menus.map(m => (
                        m.enfants && m.enfants.length == 1 &&
                        <div className="col-md-6 pt-4 mt-3">
                            <div className="card p-2 dgishadow">
                                <h5 className="card-title">{m.name}</h5>
                                <ul className="planDS">
                                    {(m.enfants && m.enfants.map(m1 => (
                                        <li key={m1.id}><a href={urlGenerator(m1)}>{m1.name}</a></li>
                                    )))}
                                </ul>
                            </div>
                        </div>
                    ))}
                    {menus && menus.map(m => (
                        m.parent == null && m.enfants && m.enfants.length == 0 && m.name != 'ACCUEIL' &&
                        <div className="col-md- mt-3">
                            <div className="card p-2 dgishadow">
                                <h5 className="card-title">{m.name}</h5>
                                <ul className="planDS">
                                    {(m.enfants && m.enfants.map(m1 => (
                                        <li key={m1.id}><a href={urlGenerator(m1)}>{m1.name}</a></li>
                                    )))}
                                </ul>
                            </div>
                        </div>
                    ))}
                    {/* {menutop && _.sortBy(menutop, ['position']).map(m => (
                        m.name != 'ACCUEIL' && m.enfants &&
                        <div className="col-md-4">
                            <div className="card p-2 dgishadow">
                                <h5 className="card-title">{m.name}</h5>
                                <ul className="planDS">
                                    {(m.enfants && m.enfants.map(m1 => (
                                        <li key={m1.id}><a href={urlGenerator(m1)}>{m1.name}</a></li>
                                    )))}
                                </ul>
                            </div>
                        </div>
                    ))}
                    {menubottom && _.sortBy(menubottom, ['position']).map(mb => (
                        mb.enfants &&
                        <div className="col-md-6 pt-4 mt-3">
                            <div className="card p-2 dgishadow">
                                <h5 className="card-title">{mb.name}</h5>
                                <ul className="planDS">
                                    {(mb.enfants && mb.enfants.map(m2 => (
                                        <li key={m2.id}><a href={urlGenerator(m2)}>{m2.name}</a></li>
                                    )))}
                                </ul>
                            </div>
                        </div>
                    ))} */}
                </div>
            </div>
        </div>
    )
}

export default PlanSite;