import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../lib/loader';
import { getResource } from '../../services/api';
import { AppContext } from '../layout';

function LeMinistre() {
    const [page, setPage] = useState('');
    const [isActive, setIsActive] = useState(true);
    let { slug, parent } = useParams();
    const { appState, setAppState } = useContext(AppContext)


    useEffect(() => {
        getResource(`ministres`).then(res => {
            setPage(res.data['hydra:member'][0])
            setAppState({ ...appState, parent_menu: parent })
        })
    }, []);

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">Le Ministre</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="">ACCUEIL</a> -</span>
                            <span>Le Ministre</span>
                        </div>
                    </div>
                </div>


            </div>
            <div className="container mb-3 mt-3">
                {page == '' ? <Loader /> : page && <div className="row" style={{ position: 'relative' }}>

                    <div className="col-sm-12 col-md-3">
                        <div className="card dgishadow" style={{ position: 'relative', zIndex: 3 }} >
                            {page && page.media && <img src={`${process.env.REACT_APP_API_BASE_URL}/${page.media.contentUrl}`} alt={page.fullName} className="img-fluid" style={{ height: '200px' }} />}
                        </div>
                        <ul className="list-group dgi-list-group2 " >
                            <li className={`list-group-item ${isActive == false ? `` : `active`}`}><a href="#" onClick={() => setIsActive(true)}>Biographie</a></li>
                            <li className={`list-group-item ${isActive == false ? `active` : ``}`}><a href="#" onClick={() => setIsActive(false)}>Mots du Ministre</a></li>

                        </ul>
                    </div>

                    <div className="col-sm-12 col-md-9 p-4 card dgishadow" style={{ minHeight: 400 }}>
                        <div className="projet_titre">
                            <h4>{page.fullName}</h4>
                            <h6>Ministère de l'Agriculture, de l'Elevage et de la Pêche</h6>
                        </div>
                        <div className="w-25 divider">
                            &nbsp;
                        </div>
                        {isActive == true ? <p className="text-justify"
                            dangerouslySetInnerHTML={{ __html: `${page.biographie}` }}
                        >

                        </p> : <p className="text-justify"
                            dangerouslySetInnerHTML={{ __html: `${page.libelle}` }}
                        >

                        </p>}
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default LeMinistre;