import React, { useState, useEffect ,useContext} from 'react';
import { useParams } from 'react-router-dom';
import { urlGenerator } from '../../lib/url';
import { getResource } from '../../services/api';
import { AppContext } from '../layout';

function Page() {
    const [page, setPage] = useState('');
    const [parent_menu, setParentMenu] = useState('');
    let { slug,parent } = useParams();
    const {appState, setAppState} = useContext(AppContext)


    useEffect(() => {
        getResource(`pages?slug=${slug}`).then(res=>{
            setPage(res.data['hydra:member'][0])
            setAppState({...appState,parent_menu:parent})
        })

        getResource(`menus/${parent}`).then(res=>{
            setParentMenu(res.data)
        })
    }, []);

    return (
        <div id="main">
        <div className="col-md-12">
            <div className="page-info">
                <h2 className="text-center text-white">{page && page.titre}</h2>
                <div className="bread-crumb">
                    <div className="container">
                        <span><a href="">ACCUEIL</a> -</span>
                        <span>{parent_menu && parent_menu.name} -</span>
                        <span>{page && page.titre}</span>
                    </div>
                </div>
            </div>


        </div>
        <div className="container mb-3 mt-3">

            <div className="row" style={{position:'relative'}}>

            {parent_menu && parent_menu.enfants.length >0 && <div className="col-md-3">
                    <ul className="list-group dgi-list-group">
                        {
                            parent_menu.enfants.filter(ef=>ef.isAside).map(ef=>(
                                <li className="list-group-item active"><a href={urlGenerator(ef)}>{ef.name}</a></li>
                            ))
                        }
                    </ul>
                </div>}


                <div className={`${parent_menu && parent_menu.enfants.filter(ef=>ef.isAside).length>0 && 'col-md-9'} p-4 card dgishadow`} style={{minHeight:400}}>

                                <p className="text-justify" 
                                 dangerouslySetInnerHTML={{__html:`${page && page.contenu}`}}
                                >
                                 
                                </p>
            
                           
                </div>
            </div>
        </div>
        </div>
         )
}

export default Page;