import React, { useEffect } from 'react';
import Slider from "react-slick";
import { Card } from 'reactstrap';
import { sliderConfig } from '../../constants';

const SliderAccueil = ({ home }) => {

    return (
        <div id="Homeslider" className="container-fluid pt-0">
            <div className="row">

                <div className="slick-slider">
                    <div className="slick-slider-init">
                        <Slider {...sliderConfig}>
                            {
                                home && home.actus_slider.map(slider => (
                                    <div className="slick-item" key={slider.id}>
                                        <div class="dgi-slide-item w-100 bg-secondary text-white"
                                            style={slider.media && { backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL + '/' + slider.media.contentUrl})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                            <div class="dgi-slide-caption">
                                                <div class="captionContent">
                                                    <h3 data-animation-in="slideInRight">
                                                        <a className="text-white" href={`/actualites/${slider.slug}`}>{slider.titre}</a>
                                                    </h3>
                                                    {/* <p data-animation-in="bounceInUp" data-delay-in="1">
                                                        <div className="p-2 bg-white" dangerouslySetInnerHTML={{ __html: `${slider.contenuPublication}` }}></div>
                                                    </p> */}
                                                    <a style={{ backgroundColor: "#fff" }} href={`/actualites/${slider.slug}`} className="btn btn-succes btn-lg mt-5 waves-effect waves-light"
                                                        data-animation-in="slideInLeft">Lire la suite
                                                        <i className="fas fa-long-arrow-alt-right" style={{ paddingLeft: '10px' }}></i></a>
                                                </div>


                                            </div>
                                        </div>
                                        {/* <div className="row" style={slider.media && {backgroundImage: url(`${slider.media.contentUrl}`)}}>
                                    <div className="col-sm-12 col-md-6 ps-0 pe-0">
                                        <div className="dgi-slide-item w-100 bg-secondary text-white">
                                            <div className="dgi-slide-caption">
                                                <h3 data-animation-in="fadeIn">
                                                    <a href={`/actualites/${slider.slug}`}>{slider.titre.slice(0,100)}...</a>
                                                   
                                                </h3>
                                                <div className="text-white actu-description mt-4 pl-0" data-animation-in="bounceInUdiv" data-delay-in="1"
                                               
                                             dangerouslySetInnerHTML={{__html:`${slider.contenuPublication.slice(0,120)} ...`}}>
                                                        
                                                   
                                                </div>
                                                <a href={`/actualites/${slider.slug}`} className="btn btn-warning btn-lg mt-5 waves-effect waves-light"
                                                        data-animation-in="slideInLeft">Lire la suite 
                                                    <i className="fas fa-long-arrow-alt-right"></i></a >
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 ps-0 pe-0 hide4mobile">
                                        <div className="comminqueImg">

                                            {slider.media && <a href={`/actualites/${slider.slug}`} ><img src={process.env.REACT_APP_API_BASE_URL + '/'  + slider.media.contentUrl} alt="" className="img-fluid"/></a>}
                                        </div>
                                    </div>
                                </div> */}
                                    </div>
                                ))
                            }

                        </Slider>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default SliderAccueil;