import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ITEM_PER_PAGE } from '../../constants';
import { getResource } from '../../services/api';
import { AppContext } from '../layout'
import moment from 'moment'
import 'moment/locale/fr'
moment.locale('fr')

function Echeances() {

    const [echeances, setEcheances] = useState([]);
    const [years, setYears] = useState([]);
    const [filter, setFilter] = useState({ year: null, order: 'desc' });
    const [parent_menu, setParentMenu] = useState('');
    let { slug, parent } = useParams();
    const { appState, setAppState } = useContext(AppContext)

    const [search, setSearch] = useState(null);
    const [openSearch, setOpenSearch] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])



    useEffect(() => {
        loadData()
    }, [filter, search, currentPage]);

    const loadData = () => {
        setYears(anneeGenerator())
        getResource(`echeance_fiscales?order[date]=desc&dateButoire[before]=${moment().format('YYYY-MM-DD')}&itemsPerPage=${ITEM_PER_PAGE}&page=${currentPage}${search ? `&libelle=${search}` : ''}`).then(res => {
            setEcheances(res.data['hydra:member'])
            let array_pages = Math.ceil(parseInt(res.data['hydra:totalItems']) / parseInt(ITEM_PER_PAGE))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
            setAppState({ ...appState, parent_menus: parent })
        })

        getResource(`menus/${parent}`).then(res => {
            setParentMenu(res.data.name)
        })
    }

    const anneeGenerator = () => {
        let year = []
        for (let index = 2000; index <= parseInt(moment().format('YYYY')); index++) {
            year.push(index)

        }
        return year
    }

    const changeAnnee = (data) => {
        setFilter({ ...filter, year: data.target.value })
    }

    return (
        <>
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white text-uppercase">Echéances ficales</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="/">ACCUEIL</a> -</span>
                            <span className="text-uppercase"> Echéances ficales</span>
                        </div>
                    </div>
                </div>


            </div>
            <div className="container mb-3 mt-3">
                <div className="searchContainer dgishadow rounded mb-3">

                    <div className={`search actulite ${openSearch && 'open'}`} onClick={() => { setOpenSearch(true) }}>

                        <div className="icon" title="Clique pour Recherchez">
                            <span>
                                <svg viewBox="0 0 40 40">
                                    <path d="M3,3 L37,37"></path>
                                </svg>
                            </span>
                        </div>
                        <div className="field">
                            <input type="text" name="search" value={search} placeholder="Que recherchez-vous ?" onChange={(event) => setSearch(event.target.value)} />
                        </div>
                    </div>
                    <div>
                        <nav className="courses-pagination pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>


                    </div>
                </div>

                <div className="row">

                    <div className="col-sm-12 col-md-12">
                        <div className="shadow">
                            <div className="event-cards-container">

                                {
                                    echeances.map((ef,index) => {
                                        let color = index+1
                                        if(index>4) color=parseInt(index%5)+1
                                            return(
                                        <div key={ef.id} class={`event event-card one-half alternate-${color}`}>
                                            <div class="event-card-date">
                                                <div class="event-start">
                                                    <div className="event-start-date">{moment(ef.dateDebut).format('DD')}</div>
                                                    <div className="event-start-month"><small>{moment(ef.dateDebut).format('MMMM')}</small></div>
                                                </div>

                                                <div class="event-end">
                                                    <div class="event-end-date">-&nbsp;{moment(ef.dateButoire).format('DD')}</div>
                                                    <div class="event-end-month"><small>&nbsp;{moment(ef.dateDebut).format('MMMM')}</small></div>
                                                </div>

                                            </div>
                                            <div class="event-card-details">
                                                <div class="event-title" title={ef.libelle}>{ef.libelle.slice(0,150)}...</div>

                                            </div>
                                        </div>
                                            )
})
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>        </>
    )
}

export default Echeances;