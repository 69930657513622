import React, { useState, useEffect } from 'react';

function RechercheIfu(props) {


    return (
        <div id="main">
        <div className="col-md-12">
            <div className="page-info mb-5">
                <h2 className="text-center text-white">Recherchez un contribuable</h2>

                <div className="bread-crumb" style={{bottom: -28}}>
                    <div className="container">
                        <span><a href="">ACCUEIL</a> -</span>
                        <span className="text-uppercase">Recherchez un contribuable</span>
                    </div>
                </div>
            </div>


        </div>
        <div className="container mb-5 mt-3">
            <div className="row">
                <div className="searchContainer-simple mb-5">
                    <div className="search open" >
                        <div className="icon" title="Recherchez un contribuable">
                            <span>
                                <svg viewBox="0 0 40 40">
                                    <path d="M3,3 L37,37"></path>
                                </svg>
                            </span>
                        </div>
                        <div className="field">
                            <input type="text" placeholder="Saisissez les mots clés"/>
                        </div>

                    </div>

                </div>
                <div className="card searchResultIfu">
                    <div className="list-group">
                        <a href="#" className="list-group-item list-group-item-action" aria-current="true">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1">1234567890123 - Super U</h5>
                                <small>Entreprise</small>
                            </div>
                            <p className="mb-1">CIME OUEME PLATEAU
                                OUEME</p>
                            <small className="badge bg-success rounded-pill">ACTIVE</small>
                        </a>
                        <a href="#" className="list-group-item list-group-item-action" aria-current="true">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1">1234567890123 - Super U</h5>
                                <small>Entreprise</small>
                            </div>
                            <p className="mb-1">CIME OUEME PLATEAU
                                OUEME</p>
                            <small className="badge bg-danger rounded-pill">ACTIVE</small>
                        </a>
                        <a href="#" className="list-group-item list-group-item-action" aria-current="true">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1">1234567890123 - Super U</h5>
                                <small>Entreprise</small>
                            </div>
                            <p className="mb-1">CIME OUEME PLATEAU
                                OUEME</p>
                            <small className="badge bg-success rounded-pill">ACTIVE</small>
                        </a>
                        <a href="#" className="list-group-item list-group-item-action" aria-current="true">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1">1234567890123 - Super U</h5>
                                <small>Entreprise</small>
                            </div>
                            <p className="mb-1">CIME OUEME PLATEAU
                                OUEME</p>
                            <small className="badge bg-success rounded-pill">ACTIVE</small>
                        </a>
                        <a href="#" className="list-group-item list-group-item-action" aria-current="true">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1">1234567890123 - Super U</h5>
                                <small>Entreprise</small>
                            </div>
                            <p className="mb-1">CIME OUEME PLATEAU
                                OUEME</p>
                            <small className="badge bg-success rounded-pill">ACTIVE</small>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    )
}

export default RechercheIfu;