import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import PoleItem from '../pole/item';
import { getResource } from '../../services/api';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { GOOGLE_MAP_API_KEY } from '../../constants';
import Geocode from "react-geocode";
import CentreItem from '../centre/item';
import { useParams } from 'react-router-dom';
import Loading from '../layout/loading';
import CommuneItem from '../centre/commune_item';
import Loader from '../../lib/loader';
import Accordeon from './accordion';

Geocode.setApiKey(GOOGLE_MAP_API_KEY);

Geocode.setLanguage("fr");
Geocode.setRegion("bj");

const PoleDeveloppement = ({ home, google }) => {
    const [centres, setCentres] = useState([]);
    const [departements, setDepartements] = useState([]);
    const [userPosition, setUserPosition] = useState(null);
    const [userAddress, setUserAddress] = useState(null);
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [activeMarker, setActiveMarker] = useState({});
    const [activeMarkerData, setActiveMarkerData] = useState(null);
    let { localite } = useParams();
    const [localisation, setLocalisation] = useState("Pobè");
    const timer = ms => new Promise(res => setTimeout(res, ms))
    const [activer, setActiver] = useState(true)
    const [atda, setATDA] = useState(null)
    const [loader, setLoader] = useState(false)
    const [allComunes, setAllCommunes] = useState(null)
    const [plateau, setPlateau] = useState(null)
    const [atlantique, setAtlantique] = useState(null)
    const [alibori, setAlibori] = useState(null)
    const [vallee, setVallee] = useState(null)
    const [borgou, setBorgou] = useState(null)
    const [atacora, setAtacora] = useState(null)
    const [zou, setZou] = useState(null)

    useEffect(() => {
        getCurrentLocation()
        loadCommunes()
        load()
        loadCentres(userAddress)
        getResource(`localites?centre.libelle=Pole 6`).then(res => {
            setPlateau(res.data['hydra:member'])
        })
        getResource(`localites?centre.libelle=Pole 7`).then(res => {
            setAtlantique(res.data['hydra:member'])
        })
        getResource(`localites?centre.libelle=Pole 2`).then(res => {
            setAlibori(res.data['hydra:member'])
        })
        getResource(`localites?centre.libelle=Pole 1`).then(res => {
            setVallee(res.data['hydra:member'])
        })
        getResource(`localites?centre.libelle=Pole 4`).then(res => {
            setBorgou(res.data['hydra:member'])
        })
        getResource(`localites?centre.libelle=Pole 3`).then(res => {
            setAtacora(res.data['hydra:member'])
        })
        getResource(`localites?centre.libelle=Pole 5`).then(res => {
            setZou(res.data['hydra:member'])
        })
    }, []);

    useEffect(() => {
        // if (userAddress) {
        //     loadCentres(userAddress)
        // }
    }, [userAddress]);

    const loadCentres = (address = null) => {
        getResource(`centres`).then(res => {
            setCentres(res.data['hydra:member'])
            for (let i = 0; i < res.data['hydra:member'].length; i++) {
                if (res.data['hydra:member'][i].libelle == "Pole 6") {
                    setATDA(res.data['hydra:member'][i])
                }
            }
        })
    }

    const loadCommunesForCentre = (centre, index) => {
        getResource(`localites?centre.libelle=${centre}`).then(res => {
            // setAllCommunes(res.data['hydra:member'])
            // setAllCommunes(state => ({
            //     ...state,
            //     [index]: res.data['hydra:member']
            // }))
        })
        // console.log(Array.from(allComunes))
    }


    const loadCommunes = (libelle) => {
        getResource(`localites?libelle=${libelle}`).then(res => {
            setDepartements(res.data['hydra:member'])
            setLoader(false)
        })
    }

    const getCurrentLocation = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setUserPosition(position)
            getCurrentAdress(position)
        });
    }

    const getCurrentAdress = (position) => {

        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            (response) => {
                const address = response.results[0].formatted_address;
                setUserAddress(address)
            },
            (error) => {
                console.error(error);
            }
        );
    }


    function onMarkerClick(props, marker, e, data) {
        setActiveMarker(marker);
        setActiveMarkerData(data);
        setShowingInfoWindow(true);
    }


    const onInfoWindowClose = () => { }

    async function load() {
        for (var i = 0; i < 3; i++) {
            await timer(2000);
            setShowMap(true)

        }
    }

    function reloadMapWithCommunes(atda, index) {
        setActiver(true)
        loadCommunesForCentre(atda.libelle, index)
        setLocalisation(atda.libelle)
        setATDA(atda)
    }

    function putDescCommune(libelle) {
        setDepartements([])
        setLoader(true)
        setActiver(false)
        loadCommunes(libelle)
    }

    return (
        <div id="poleDevelop">
            {centres && centres.length > 0 && <div>
                <h3 className="fw-bold text-center titleHolder">Pôle de Développement Agricole</h3>
                <div className="row">
                    <div className="col-sm-12 col-md-3 col-lg-2 border-end mt-3">
                        <h6 className="secdText border-bottom p-2">NOM DU POLE</h6>
                        <div className="accordion row" id="centeraccordeon">
                            {/* <Accordeon /> */}
                            {centres.map((ctr, index) => (
                                <div key={ctr.id} className="accordion-item  mt-3 col-sm-12  ">
                                    <h2 className="accordion-header" style={{ marginLeft: "10px" }} id="headingOne">
                                        <a className="accordion-button collapsed text-uppercase " type="button" data-bs-toggle="collapse"
                                            data-bs-target={`#faqtop${ctr.id}`} onClick={() => reloadMapWithCommunes(ctr, index)} aria-expanded="false" aria-controls={`faqtop${index}`} style={{ fontSize: '14px' }}>
                                            <small className=""><b>{ctr.libelle}</b> </small>
                                        </a>
                                    </h2>
                                    <div id={`faqtop${ctr.id}`} className="accordion-collapse collapse" aria-labelledby="headingOne"
                                        data-bs-parent="#centeraccordeon">
                                        <div className="border-bottom">
                                            <p className="justify-content-around" style={{ marginLeft: "10px" }} >
                                                <ul className="list-group dgi-list-group2">
                                                    {
                                                        index == 0 ? plateau != null && plateau.map(plt => (
                                                            <a className="pole-child-button" onClick={() => {
                                                                setLocalisation(plt.libelle)
                                                                putDescCommune(plt.libelle)
                                                            }}>
                                                                <li className={`list-group-item ac ${departements.length > 0 && departements[0].libelle == plt.libelle ? `active` : ``}`}><a style={{ fontSize: '12px' }}><small><b>{plt.libelle}</b> </small></a></li>
                                                            </a>
                                                        ))
                                                            : index == 1 ? atlantique != null && atlantique.map(atl => (
                                                                <a className="pole-child-button" onClick={() => {
                                                                    setLocalisation(atl.libelle)
                                                                    putDescCommune(atl.libelle)
                                                                }}>
                                                                    <li className={`list-group-item ac ${departements.length > 0 && departements[0].libelle == atl.libelle ? `active` : ``}`}><a style={{ fontSize: '12px' }}><small><b>{atl.libelle}</b> </small></a></li>
                                                                </a>

                                                            )) : index == 2 ? alibori != null && alibori.map(atl => (
                                                                <a className="pole-child-button" onClick={() => {
                                                                    setLocalisation(atl.libelle)
                                                                    putDescCommune(atl.libelle)
                                                                }}>
                                                                    <li className={`list-group-item ac ${departements.length > 0 && departements[0].libelle == atl.libelle ? `active` : ``}`}><a style={{ fontSize: '12px' }}><small><b>{atl.libelle}</b> </small></a></li>
                                                                </a>
                                                            )) : index == 3 ? vallee != null && vallee.map(atl => (
                                                                <a className="pole-child-button" onClick={() => {
                                                                    setLocalisation(atl.libelle)
                                                                    putDescCommune(atl.libelle)
                                                                }}>
                                                                    <li className={`list-group-item ac ${departements.length > 0 && departements[0].libelle == atl.libelle ? `active` : ``}`}><a style={{ fontSize: '12px' }}><small><b>{atl.libelle}</b> </small></a></li>
                                                                </a>
                                                            )) : index == 4 ? borgou != null && borgou.map(atl => (
                                                                <a className="pole-child-button" onClick={() => {
                                                                    setLocalisation(atl.libelle)
                                                                    putDescCommune(atl.libelle)
                                                                }}>
                                                                    <li className={`list-group-item ac ${departements.length > 0 && departements[0].libelle == atl.libelle ? `active` : ``}`}><a style={{ fontSize: '12px' }}><small><b>{atl.libelle}</b> </small></a></li>
                                                                </a>
                                                            )) : index == 5 ? atacora != null && atacora.map(atl => (
                                                                <a className="pole-child-button" onClick={() => {
                                                                    setLocalisation(atl.libelle)
                                                                    putDescCommune(atl.libelle)
                                                                }}>
                                                                    <li className={`list-group-item ac ${departements.length > 0 && departements[0].libelle == atl.libelle ? `active` : ``}`}><a style={{ fontSize: '12px' }}><small><b>{atl.libelle}</b> </small></a></li>
                                                                </a>
                                                            )) : index == 6 ? zou != null && zou.map(atl => (
                                                                <a className="pole-child-button" onClick={() => {
                                                                    setLocalisation(atl.libelle)
                                                                    putDescCommune(atl.libelle)
                                                                }}>
                                                                    <li className={`list-group-item ac ${departements.length > 0 && departements[0].libelle == atl.libelle ? `active` : ``}`}><a style={{ fontSize: '12px' }}><small><b>{atl.libelle}</b> </small></a></li>
                                                                </a>
                                                            )) : <div></div>
                                                    }
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 mt-3">
                        {/* <h6 className="secdText border-bottom p-2 text-uppercase">Informations Complémentaires</h6>
                            <PoleItem /> */}
                        <h6 className="secdText border-bottom p-2 text-uppercase">Structure Administrative</h6>
                        {

                            activer == true ? atda && <CentreItem centre={atda} commune={departements} /> :
                                departements.length > 0 && <CommuneItem centre={atda} commune={departements} />

                        }
                    </div>
                    <div className="col-md-5 mt-3">
                        <h6 className="secdText border-bottom p-2 text-uppercase">Localiser sur la carte</h6>
                        <div className="card p-2 mt-2 dgishadow">
                            {/*Google map*/}
                            <div style={{ height: '75vh', width: '100%' }}>

                                {atda != null ? showMap && <Map
                                    google={google}
                                    zoom={11}
                                    initialCenter={
                                        {
                                            lat: userPosition && !localisation ? userPosition.coords.latitude : centres.length > 0 ? centres[0].latitude : 6.37,
                                            lng: userPosition && !localisation ? userPosition.coords.longitude : centres.length > 0 ? centres[0].longitude : 2.40
                                            // lat: 1.000000000,
                                            // long: 1.000000000
                                        }
                                    }
                                >

                                    {
                                        // centres.map(centre => (
                                        <Marker
                                            onClick={(props, marker, e) => onMarkerClick(props, marker, e, atda)}

                                            position={{
                                                lat: atda.latitude,
                                                lng: atda.longitude
                                            }}
                                            key={atda.id}
                                            icon={{
                                                url: "/img/marker.png",
                                                anchor: new google.maps.Point(32, 32),
                                                scaledSize: new google.maps.Size(32, 32)
                                            }}
                                            name={'Current location'} />
                                        // ))
                                    }



                                    <InfoWindow
                                        google={google}
                                        onClose={onInfoWindowClose}
                                        marker={activeMarker}

                                        visible={showingInfoWindow}>

                                        {activeMarkerData && <h5 className="card-title"><a href="" className="textPrimary">{activeMarkerData.libelle}</a></h5>}
                                    </InfoWindow>
                                </Map> : <div></div>}


                            </div>
                            {/*Google map*/}

                        </div>
                        {/* <div className="row space-rows" id="animated-cards">
                                {
                                    _.sortBy(home.usage_services, ['position']).map(us => (
    
    
    
                                        <div className="col-sm-12 col-md-4 mt-5">
                                            <Button variant="secondary" onClick={handleShow} >
                                                <div className="card cards-shadown cards-hover">
                                                    <div className="card-header">
                                                        <span className="space">
                                                            <i className="fas fa-link -shield service-icon"></i>
                                                        </span>
                                                        <div className="cardheader-text ">
                                                            <h4 className="heading-card text-uppercase">{us.libelle}
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Button>
                                            <Modal show={show} onHide={handleClose} centered size="lg">
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Modal heading</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>
                                                    <Button variant="primary" onClick={handleClose}>
                                                        Save Changes
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
    
                                    ))
                                }
                            </div> */}
                            

                    </div>
                </div>

            </div>
            }
        </div>
    );
}

export default GoogleApiWrapper({
    apiKey: (GOOGLE_MAP_API_KEY)
})(PoleDeveloppement)