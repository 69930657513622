import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../lib/loader';
import { urlGenerator } from '../../lib/url';
import { getResource } from '../../services/api';
import { AppContext } from '../layout';

function SousMenusMinistere() {
    const [publication, setPublication] = useState(null);
    let { slug, parent } = useParams();
    const { appState, setAppState } = useContext(AppContext)

    useEffect(() => {
        getResource(`layout`).then(res => {
            setPublication(res.data['menus'][10])
            // console.log(res.data['menus'][10].enfants)
            setAppState({ ...appState, parent_menu: parent })
        })


    }, []);

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">LE MINISTERE</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="/">ACCUEIL</a> -</span>
                            <span><a href="/">Sous menus du ministère</a></span>
                        </div>
                    </div>
                </div>


            </div>
            {publication == null ? <Loader /> :
                <div className="container mb-3 mt-3">
                    <div className="row" style={{ position: 'relative' }}>
                        {publication && <div className="col-sm-12 col-md-12 border-bottom-3">
                            <div className="">

                                {/* <div className="d-flex justify-content-between bckColorDark p-3 text-white">
                                    Sous menus du ministère
                                </div> */}
                                <div className="page-content sous-menus_content mt-3" style={{ fontWeight: "bold" }}>
                                    {publication && <div className="row">
                                        <div className="col-md-3 dim">
                                            <div className="dgishadow smenu-hover m-2">
                                                <div className="projet_content_key1 text-center p-3"><a href={urlGenerator(publication.enfants[0])}>{publication.enfants[0].name}</a></div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 dim">
                                            <div className="dgishadow smenu-hover m-2">
                                                <div className="projet_content_key1 text-center p-3"><a href={urlGenerator(publication.enfants[1])}>{publication.enfants[1].name}</a></div>
                                            </div>
                                        </div>
                                        <div className="dgishadow smenu-hover col-md-3 m-2">
                                            <div className="projet_content_key1 text-center p-3"><a href={urlGenerator(publication.enfants[2])}>{publication.enfants[2].name}</a></div>
                                        </div>
                                        <div className="col-md-3 dim">
                                            <div className="dgishadow smenu-hover m-2">
                                                <div className="projet_content_key1 text-center p-3"><a href={urlGenerator(publication.enfants[3])}>{publication.enfants[3].name}</a></div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 dim">
                                            <div className="dgishadow smenu-hover m-2">
                                                <div className="projet_content_key1 text-center p-3"><a href={urlGenerator(publication.enfants[4])}>{publication.enfants[4].name}</a></div>
                                            </div>
                                        </div>
                                        <div className="dgishadow smenu-hover col-md-3 m-2">
                                            <div className="projet_content_key1 text-center p-3"><a href={urlGenerator(publication.enfants[5])}>{publication.enfants[5].name}</a></div>
                                        </div>
                                        <div className="col-md-3 dim">
                                            <div className="dgishadow smenu-hover m-2">
                                                <div className="projet_content_key1 text-center p-3"><a href={urlGenerator(publication.enfants[6])}>{publication.enfants[6].name}</a></div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 dim">
                                            <div className="dgishadow smenu-hover m-2">
                                                <div className="projet_content_key1 text-center p-3"><a href={urlGenerator(publication.enfants[7])}>{publication.enfants[7].name}</a></div>
                                            </div>
                                        </div>
                                        <div className="dgishadow smenu-hover col-md-3 m-2">
                                            <div className="projet_content_key1 text-center p-3"><a  href={urlGenerator(publication.enfants[8])}>{publication.enfants[8].name}</a></div>
                                        </div>
                                        <div className="col-md-3 dim">
                                            <div className="dgishadow smenu-hover m-2">
                                                <div className="projet_content_key1 text-center p-3"><a href={urlGenerator(publication.enfants[9])}>{publication.enfants[9].name}</a></div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 dim">
                                            <div className="dgishadow smenu-hover m-2">
                                                <div className="projet_content_key1 text-center p-3"><a href={urlGenerator(publication.enfants[10])}>{publication.enfants[10].name}</a></div>
                                            </div>
                                        </div>

                                    </div>}
                                </div>
                            </div>
                        </div>}
                    </div>

                </div>
            }
        </div>
    )
}

export default SousMenusMinistere;