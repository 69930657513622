import axios from 'axios';

export default axios.create({
  baseURL: "https://www.googleapis.com/youtube/v3",
  params: {
    part: 'snippet,id',
    maxResults: 12,
    order: 'date',
    type: 'video',
    channelId: 'UCuvwV6Zvdd3a6niWEZf3nJg',
    key: 'AIzaSyBlu1whTu2crHN_sKXaiN0zpO-UWGxcjko'
  }
})