import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getResource } from '../../services/api';
import { AppContext } from '../layout'

import moment from 'moment'
import { ITEM_PER_PAGE } from '../../constants';
moment.locale('fr')
function Reformes() {

    const [publications, setPublications] = useState([]);
    const [search, setSearch] = useState(null);
    const [openSearch, setOpenSearch] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])


    useEffect(() => {
        loadData()
    }, [search]);

    useEffect(() => {
        loadData()
    }, [currentPage]);

    const loadData = () => {
        getResource(`reformes?itemsPerPage=${ITEM_PER_PAGE}&page=${currentPage}${search ? `&titre=${search}` : ''}`).then(res => {
            setPublications(res.data['hydra:member'])
            let array_pages = Math.ceil(parseInt(res.data['hydra:totalItems']) / parseInt(ITEM_PER_PAGE))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
        })
    }

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">Réformes</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="/">ACCUEIL</a> -</span>
                            <span>REFORMES</span>
                        </div>
                    </div>
                </div>


            </div>

            <div className="container mb-3 mt-3">
                <div className="searchContainer dgishadow rounded">

                    <div className={`search actulite ${openSearch && 'open'}`} onClick={() => { setOpenSearch(true) }}>

                        <div className="icon" title="Clique pour Recherchez">
                            <span>
                                <svg viewBox="0 0 40 40">
                                    <path d="M3,3 L37,37"></path>
                                </svg>
                            </span>
                        </div>
                        <div className="field">
                            <input type="text" name="search" value={search} placeholder="Que recherchez-vous ?" onChange={(event) => setSearch(event.target.value)} />
                        </div>
                    </div>
                    <div>
                        <nav className="courses-pagination pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>


                    </div>
                </div>
                <div className="row mt-3">
                    {publications && publications.map(act => (
                        <div key={act.id} className="actu-items col-sm-12 col-lg-4 mt-3">
                            <div className="single-actu-item">
                                <div className="actu-image">
                                    <a href={`/actualites/${act.slug}`}>
                                        {act.media && <img src={process.env.REACT_APP_API_BASE_URL + '/' + act.media.contentUrl} alt={act.titre}
                                            className="img-fluid" />}
                                        {!act.media && <img src={'/img/noimage.jpg'} alt={act.titre}
                                            className="img-fluid" />}
                                    </a>
                                    <div className="shareBtn">
                                        <div className="shareBtn-content">
                                            <a href="" className="z-depth-2 "><i
                                                className="social-icons fab fa-facebook"></i></a>
                                            <a href="" className="z-depth-2 "><i
                                                className="social-icons fab fa-twitter"></i></a>
                                            <a href="" className="z-depth-2 "><i
                                                className="social-icons fab fa-youtube"></i></a>
                                        </div>
                                        <span className="z-depth-2"><i className="fas fa-share-alt"></i></span>

                                    </div>
                                    <div className="actu-meta">
                                        <p>{moment(act.createdAt).format('DD-MM-YYYY')}</p>
                                    </div>
                                </div>
                                <div className="actu-text">
                                    <a href={`/actualites/${act.slug}`}>
                                        <h3 className="text-uppercase">{act.titre.slice(0, 46)}...</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>


        </div>
    )
}

export default Reformes;