import React from 'react';
import LinkUrl from '../../lib/link';

function FilAriane({ ariane }) {

    return (

        <div className="bread-crumb">
            <div className="container">
                {ariane.primary && ariane.primary.url && <span><LinkUrl to={ariane.primary.url}>{ariane.primary.title && ariane.primary.title.toUpperCase()}</LinkUrl>  /  </span>}
                {ariane.primary && !ariane.primary.url && <span>{ariane.primary.title && ariane.primary.title.toUpperCase()}  / </span>}

                {ariane.secondary && ariane.secondary.url && <span><LinkUrl to={ariane.secondary.url}>{ariane.secondary.title && ariane.secondary.title.toUpperCase()}</LinkUrl>  {ariane.tertiary && '/'}  </span>}
                {ariane.secondary && !ariane.secondary.url && <span>{ariane.secondary.title && ariane.secondary.title.toUpperCase()}   {ariane.tertiary && '/'}  </span>}

                {ariane.tertiary && ariane.tertiary.url && <span><LinkUrl to={ariane.tertiary.url}>{ariane.tertiary.title && ariane.tertiary.title.toUpperCase()}</LinkUrl>  /  </span>}
                {ariane.tertiary && !ariane.tertiary.url && <span>{ariane.tertiary.title && ariane.tertiary.title.toUpperCase()}  </span>}

            </div>
        </div>
    )
}

export default FilAriane;