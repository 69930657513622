import React, { useState, useEffect } from 'react';

function CentreItem({ centre, commune }) {

    return (
        <div key={centre.id} className="card mb-3 mt-3 dgishadow" style={{ minHeight: '400px', maxWidth: 540 }}>
            <div className="row g-0 les-centre ">
                <div className="col-md-4 p-0 vide" >
                </div>
                <div className="col-md-8 float-left">
                    <div className="card-body ">
                        <img src={centre.media == null ? `/img/logo.jpg` : `${process.env.REACT_APP_API_BASE_URL}/${centre.media.contentUrl}`} height="120" width="120" className="centre-img" alt={centre.libelle} />
                        <h5 className="card-title"><a href="" className="textPrimary">ATDA {centre.libelle}</a>
                            {/* <span className="badge bg-secondary">{centre.departement.libelle}</span> */}
                        </h5>
                        <div className="border-top ">

                            <div>
                                <p className="text-muted xs-font">Responsable</p>
                                <small className=""><b>{centre.responsable}</b> </small>
                            </div>
                            <div>
                                <p className="text-muted xs-font">Filières</p>
                                <small className=""><b>{centre.filiere}</b> </small>
                            </div>

                        </div>
                        {
                            commune.length > 0 && <div className="border-top mt-3">

                                <div>
                                    <p className="text-muted xs-font">Siège</p>
                                    <small className=""><p className="text-justify"
                                        dangerouslySetInnerHTML={{ __html: `${centre.siege}` }}
                                    >
                                    </p></small>
                                </div>

                            </div>
                        }
                        <div className="d-flex justify-content-between ">
                            <div>
                                {centre.serviceContacts && centre.serviceContacts.map(cs => (<span className="xs-font text-uppercase" key={cs.id}>{cs.service.libelle} ( {cs.typeContact.libelle} : {cs.valeur} ) <br /></span>))}
                            </div>
                        </div>

                        <p className="card-text"></p>
                    </div>
                </div>

                <div className="border-top " style={{ paddingLeft: "20px" }}>
                    <div>
                        <p className="text-muted xs-font">Couverture spaciale</p>
                        <small className=""><b>{centre.couverture}</b> </small>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default CentreItem;