import React from 'react';

function CommuneItem({ centre, commune }) {

    return (
        <div key={commune.id} className="card mb-3 mt-3 dgishadow" style={{ minheight: '600px', maxWidth: 540 }}>
            <div className="row g-0">
                <div className="col-md-12">
                    <h5 className="card-title m-2 p-2"><a href="" className="textPrimary">ATDA {centre.libelle} </a>
                        {/* <span className="badge bg-secondary">{centre.departement.libelle}</span> */}
                    </h5>
                    <div className="card-body">
                        {/* <img src="/img/logo.jpg" height="120" width="120" className="centre-img" alt="logo MAEP" /> */}
                        <h5 className="card-title"><a href="" className="textPrimary">Commune {commune[0].libelle}</a>
                            {/* <span className="badge bg-secondary">{centre.departement.libelle}</span> */}
                        </h5>
                        {/* <div className="border-top ">

                            <div>
                                <p className="text-muted xs-font">Filières</p>
                                <small className=""><b>{commune.filieres}</b> </small>
                            </div>

                        </div> */}
                        <p className="card-text"></p>
                    </div>
                </div>

                <div className="border-top " style={{ paddingLeft: "20px" }}>
                    <div>
                        <p className="text-muted xs-font" dangerouslySetInnerHTML={{ __html: `${commune[0].description}` }}></p>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default CommuneItem;