import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getResource } from '../../services/api';
import { AppContext } from '../layout';
import Loader from '../../lib/loader';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import Geocode from "react-geocode";
import { GOOGLE_MAP_API_KEY } from '../../constants';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
const timer = ms => new Promise(res => setTimeout(res, ms))

Geocode.setApiKey(GOOGLE_MAP_API_KEY);

Geocode.setLanguage("fr");
Geocode.setRegion("bj");

function Direction({ google }) {
    const [page, setPage] = useState('');
    const [directions, setDirections] = useState('');
    const [loading, setLoading] = useState(false);
    const [helpTuto, setHelpTuto] = useState(false);
    let { libUnique, parent, name } = useParams();
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [activeMarker, setActiveMarker] = useState({});
    const [activeMarkerData, setActiveMarkerData] = useState(null);
    const { appState, setAppState } = useContext(AppContext)


    useEffect(() => {
        setLoading(true)
        getResource(`directions?module.libUnique=${libUnique}&itemsPerPage=5`).then(res => {
            setDirections(res.data['hydra:member'])
            setPage(res.data['hydra:member'][0])
            setAppState({ ...appState, parent_menu: parent })
            setLoading(false)
        })
    }, []);

    const getDirection = (libelle) => {
        setPage('')
        setLoading(true)
        getResource(`directions?libelle=${libelle}`).then(res => {
            setPage(res.data['hydra:member'][0])
            setLoading(false)
        })
    }

    function onMarkerClick(props, marker, e) {
        setActiveMarker(marker);
        // setActiveMarkerData(data);
        setShowingInfoWindow(true);
    }


    const onInfoWindowClose = () => { }

    async function load() {
        for (var i = 0; i < 3; i++) {
            await timer(2000);
            setShowMap(true)

        }
    }

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">{name}</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="">ACCUEIL</a> -</span>
                            <span>Ministère -</span>
                            <span>{name}</span>
                        </div>
                    </div>
                </div>


            </div>
            {loading == true ? <Loader /> :
                <div className="container mb-3 mt-3">
                    {directions.length == 0 ? <div className="row dgishadow m-3" style={{ height: '200px' }}>
                        <span className="text-center">Aucune donnée disponible</span>
                    </div> : <div></div>}
                    {page && <div className="row" style={{ position: 'relative' }}>

                        {directions && <div className="col-md-3">
                            <ul className="list-group dgi-list-group2">
                                {
                                    directions.map(dir => (
                                        <button className='btn m-2 projet_content_key1 text-center' onClick={() => getDirection(dir.libelle)}>
                                            <li className={`list-group-item ac ${page.id == dir.id ? `active` : ``}`}>{dir.libelle}</li>
                                        </button>

                                    ))
                                }
                                {directions.length > 5 && <p className="text-center"><a href={`/direction/${name}/${libUnique}`}>Voir plus <i className="fas fa-caret-down"></i></a></p>}
                            </ul>
                        </div>}

                        <div className="col-sm-12 col-md-9 p-4 card dgishadow" style={{ minHeight: 400 }}>
                            <div className="projet_titre">
                                <h4>{page.libelle}</h4>
                            </div>
                            <div className="w-25 divider">
                                &nbsp;
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-5 dgishadow p-2" style={{ position: 'relative', zIndex: 3 }} >
                                    {page && page.media != null ? <img src={`${process.env.REACT_APP_API_BASE_URL}/${page.media.contentUrl}`} alt={page.directeur} className="img-fluid" style={{ height: '200px', width: '300px' }} /> :  <img src={'/img/noimage.jpg'} alt={page.directeur} className="img-fluid" style={{ height: '200px', width: '300px' }} />}
                                </div>
                                <div className="col-sm-12 col-md-7 dgishadow">
                                    <div className="d-flex dgishadow mb-3" id="alertinfo_top" >
                                        <div className="p-1 pe-2 bd-highlight textG titre-head1">
                                            <span className="">Directeur </span>
                                        </div>

                                        <div className="projet_content_key1 p-2">{page.directeur}</div>
                                    </div>
                                    <div className="d-flex dgishadow mb-3" id="alertinfo_top" >
                                        <div className="p-1 pe-2 bd-highlight textG titre-head1">
                                            <span className="">Contact </span>
                                        </div>

                                        <div className="projet_content_key1 p-2">{page.contact}</div>
                                    </div>
                                    <div className="d-flex dgishadow justify-content-between" id="alertinfo_top" >
                                        <div className="p-1 pe-2 bd-highlight textG titre-head1">
                                            <span className=" ">Situation Géographique </span>
                                        </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className="projet_content_key1 p-2">{page.localisation}</div>

                                        {/* <div className="projet_content_key1"><Button style={{ padding: '10px' }} type="button" onClick={() => setHelpTuto(true)}><i class="fas fa-map-marker"></i><br /></Button></div> */}
                                    </div>
                                    {/* <div className="projet_content_key1 pb-3"><div className="p-1 pe-2 bd-highlight bg-dark text-white titre-flash">
                                        <span className="hide4mobile">Directeur </span>
                                    </div>{"  "} {page.directeur}</div> */}
                                    {/* <div className="projet_content_key1 content-divider pt-4 pb-3"><span className="projet_key1">Contact:</span>{"  "} {page.contact}</div> */}
                                    {/* <div className="projet_content_key1 content-divider pt-4"><span className="projet_key1">Situation géographique:</span>{"  "} {page.localisation}</div> */}
                                </div>
                            </div>&nbsp;
                            <div>
                                <div className="d-flex" >
                                    <div className="p-1 pe-2 bd-highlight  textG titre-head1">
                                        <span className=" p-2">Mission et Attributions </span>
                                    </div>
                                </div>
                                {/* <h6 className="projet_content_key2">Missions et Attributions</h6> */}
                                <div dangerouslySetInnerHTML={{ __html: `${page.mission}` }}></div>
                            </div> &nbsp;
                            <Modal isOpen={helpTuto} toggle={() => setHelpTuto(false)} size='md'>
                                <ModalHeader toggle={() => setHelpTuto(false)}>Situation Géographique</ModalHeader>
                                <ModalBody>
                                    <h6 className="secdText border-bottom p-2 text-uppercase">Localiser sur la carte</h6>
                                    <div className="card p-2 mt-2 dgishadow">
                                        <div style={{ height: '75vh', width: '100%' }}>

                                            {showMap && <Map
                                                google={google}
                                                zoom={11}
                                                initialCenter={
                                                    {
                                                        // lat: userPosition && !localisation ? userPosition.coords.latitude : centres.length > 0 ? centres[0].latitude : 6.37,
                                                        // lng: userPosition && !localisation ? userPosition.coords.longitude : centres.length > 0 ? centres[0].longitude : 2.40
                                                        lat: 1.000000000,
                                                        long: 1.000000000
                                                    }
                                                }
                                            >

                                                {
                                                    // centres.map(centre => (
                                                    <Marker
                                                        onClick={(props, marker, e) => onMarkerClick(props, marker, e)}

                                                        position={{
                                                            lat: 1.000000000,
                                                            long: 1.000000000
                                                            // lat: atda.latitude,
                                                            // lng: atda.longitude
                                                        }}
                                                        key={"21"}
                                                        icon={{
                                                            url: "/img/marker.png",
                                                            anchor: new google.maps.Point(32, 32),
                                                            scaledSize: new google.maps.Size(32, 32)
                                                        }}
                                                        name={'Current location'} />
                                                    // ))
                                                }



                                                <InfoWindow
                                                    google={google}
                                                    onClose={onInfoWindowClose}
                                                    marker={activeMarker}

                                                    visible={showingInfoWindow}>

                                                    {activeMarkerData && <h5 className="card-title"><a href="" className="textPrimary">{activeMarkerData.libelle}</a></h5>}
                                                </InfoWindow>
                                            </Map>}


                                        </div>


                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={() => setHelpTuto(false)}>Fermer</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </div>}
                </div>
            }
        </div>
    )
}

export default Direction;