import React, { useEffect, useState } from 'react'
import youtube from "../../services/youtube";
import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    TelegramShareButton,
} from "react-share";
import { getResource } from '../../services/api';
import moment from "moment"
import 'moment/locale/fr'
import YoutubePlay from './youtubePlay';
moment.locale('fr')



const Actualites = ({ home }) => {
    const [nextToken, setNextToken] = useState('')
    const [prevToken, setPrevToken] = useState('')
    const [next, setNext] = useState(false)
    const [prev, setPrev] = useState(false)
    const [documents, setDocuments] = useState([])
    const [moduleVideos, setModuleVideos] = useState([])

    useEffect(() => {
        getResource(`documents`).then(res => {
            setDocuments(res.data['hydra:member'])
        })
        youtube.get('/search', {
            params: {
                pageToken: nextToken
            }
        }).then(res => {
            // console.log(res.data)
            setModuleVideos(res.data.items);

            setNextToken(res.data.nextPageToken)
        })
    }, [next])

    useEffect(() => {
        youtube.get('/search', {
            params: {
                pageToken: prevToken
            }
        }).then(res => {
            setModuleVideos(res.data.items);
            setPrevToken(res.data.prevPageToken)
        })
    }, [prev])

    return (
        <div id="lesactualite">
            <h2 className="fw-bold text-center titleHolder">Retrouvez toute l’actualité</h2>
            <div className="container ps-0 pe-0">
                <ul className="nav nav-tabs mt-4" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#actualites"
                            type="button"
                            role="tab" aria-controls="home" aria-selected="true">ACTUALITÉS
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#comminques" type="button"
                            role="tab"
                            aria-controls="profile" aria-selected="false">DOCUMENTS RECENTS
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-uppercase" data-bs-toggle="tab" data-bs-target="#revuepresse"
                            type="button"
                            role="tab"
                            aria-controls="contact" aria-selected="false">vidéothèque
                        </button>
                    </li>
                </ul>
                <div className="tab-content " id="myTabContent">
                    <div className="tab-pane fade show active" id="actualites" role="tabpanel">
                        <div className="row">
                            {
                                home && home.actus.filter(a => a.categorie.libUnique == "actus").slice(0, 3).map(act => (
                                    <div key={act.id} className="col-sm-12 col-lg-4 mt-3">
                                        <div className="single-actu-item">
                                            <div className="actu-image">
                                                <a href={`/actualites/${act.slug}`}>
                                                    {act.media && <img src={process.env.REACT_APP_API_BASE_URL + '/' + act.media.contentUrl} height='200px' alt="news 1"
                                                        className="img-fluid" />}
                                                    {!act.media && <img src={'/img/noimage.jpg'} alt="news 1"
                                                        className="img-fluid" />}
                                                </a>
                                                <div className="shareBtn">
                                                    <FacebookShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_BASE_URL}/${`actualites/${act.slug}`}`}>
                                                        <i
                                                            className="fab fa-facebook"></i>
                                                    </FacebookShareButton>
                                                    <WhatsappShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_BASE_URL}/${`actualites/${act.slug}`}`}>
                                                        <i
                                                            className="fab fa-whatsapp"></i>
                                                    </WhatsappShareButton>
                                                    <TelegramShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_BASE_URL}/${`actualites/${act.slug}`}`}>
                                                        <i
                                                            className="fab fa-telegram"></i>
                                                    </TelegramShareButton>
                                                    <TwitterShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_BASE_URL}/${`actualites/${act.slug}`}`}>
                                                        <i
                                                            className="fab fa-twitter"></i>
                                                    </TwitterShareButton>

                                                </div>
                                                <div className="actu-meta">
                                                    <p>Publié le {moment(act.createdAt).format('DD MMMM YYYY')}</p>
                                                </div>
                                            </div>
                                            <div className="actu-text">
                                                <a href={`/actualites/${act.slug}`}>
                                                    <h4 className="">{act.titre.length > 45 ? act.titre.slice(0, 45) + '...' : act.titre }</h4>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                ))
                            }

                        </div>
                        <button className="actu-btn  mx-auto d-block mt-4 mb-3 waves-effect waves-orange waves-ripple">
                            <a href="/actualites" style={{ marginTop: -3, color: '#FFF' }}>VOIR PLUS</a> <i
                                className="fas fa-long-arrow-alt-right"></i></button>
                    </div>
                    <div className="tab-pane fade" id="comminques" role="tabpanel">

                        {documents.length > 0 &&

                            <div>
                                <a href={`documentations/1ab9e3f6-7f9b-4f80-aa57-2f604580e3b2/demarches-administratives`} className="list-group-item list-group-item-action" aria-current="true">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="commiqueTitle">{documents[0].libelle}</h5>
                                        <p className="comminqueDate"><a href={documents[0].fichier && process.env.REACT_APP_API_BASE_URL + '/' + documents[0].fichier.contentUrl} className="btn btn-sm btn-outline-warning text-dark "
                                            title="TELECHARGE"><i title="TELECHARGE" className="fas fa-download xs-font"></i></a></p>
                                    </div>
                                </a>
                                <a href={`documentations/1ab9e3f6-7f9b-4f80-aa57-2f604580e3b2/demarches-administratives`} className="list-group-item list-group-item-action" aria-current="true">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="commiqueTitle">{documents[1].libelle}</h5>
                                        <p className="comminqueDate"><a href={documents[1].fichier && process.env.REACT_APP_API_BASE_URL + '/' + documents[1].fichier.contentUrl} className="btn btn-sm btn-outline-warning text-dark "
                                            title="TELECHARGE"><i title="TELECHARGE" className="fas fa-download xs-font"></i></a></p>
                                    </div>
                                </a>
                                <a href={`documentations/1ab9e3f6-7f9b-4f80-aa57-2f604580e3b2/demarches-administratives`} className="list-group-item list-group-item-action" aria-current="true">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="commiqueTitle">{documents[2].libelle}</h5>
                                        <p className="comminqueDate"><a href={documents[2].fichier && process.env.REACT_APP_API_BASE_URL + '/' + documents[2].fichier.contentUrl} className="btn btn-sm btn-outline-warning text-dark "
                                            title="TELECHARGE"><i title="TELECHARGE" className="fas fa-download xs-font"></i></a></p>
                                    </div>
                                </a>
                                <a href={`documentations/1ab9e3f6-7f9b-4f80-aa57-2f604580e3b2/demarches-administratives`} className="list-group-item list-group-item-action" aria-current="true">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="commiqueTitle">{documents[3].libelle}</h5>
                                        <p className="comminqueDate"><a href={documents[3].fichier && process.env.REACT_APP_API_BASE_URL + '/' + documents[3].fichier.contentUrl} className="btn btn-sm btn-outline-warning text-dark "
                                            title="TELECHARGE"><i title="TELECHARGE" className="fas fa-download xs-font"></i></a></p>
                                    </div>
                                </a>
                                <a href={`documentations/1ab9e3f6-7f9b-4f80-aa57-2f604580e3b2/demarches-administratives`} className="list-group-item list-group-item-action" aria-current="true">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="commiqueTitle">{documents[4].libelle}</h5>
                                        <p className="comminqueDate"><a href={documents[4].fichier && process.env.REACT_APP_API_BASE_URL + '/' + documents[4].fichier.contentUrl} className="btn btn-sm btn-outline-warning text-dark "
                                            title="TELECHARGE"><i title="TELECHARGE" className="fas fa-download xs-font"></i></a></p>
                                    </div>
                                </a>
                            </div>

                            // home.communiques.slice(0, 3).map(act => (
                            //     <a key={act.id} href={`/communiques/${act.slug}`} className="list-group-item list-group-item-action" aria-current="true">
                            //         <div className="d-flex w-100 justify-content-between">
                            //             <h5 className="commiqueTitle">{act.titre}</h5>
                            //             <p className="comminqueDate"><i className="fas fa-calendar-day"></i> <br />{moment(act.createdAt).format('DD')}<br /> {moment(act.createdAt).format('MMMM')} <br /> <span
                            //                 className="annee">{moment(act.createdAt).format('YYYY')}</span></p>
                            //         </div>
                            //     </a>

                            // ))
                        }

                        <button className="actu-btn  mx-auto d-block mt-4 mb-3 waves-effect waves-orange waves-ripple">
                            <a href="/documentations/1ab9e3f6-7f9b-4f80-aa57-2f604580e3b2/demarches-administratives" style={{ marginTop: -3, color: '#FFF' }}>VOIR PLUS</a> <i
                                className="fas fa-long-arrow-alt-right"></i></button>
                    </div>
                    <div className="tab-pane fade" id="revuepresse" role="tabpanel">
                        {/* <YoutubePlay /> */}
                        <ul className="gallery caption-1 mt-4">
                            {
                                moduleVideos.slice(0, 3).map(module => (
                                    <li>
                                        <YoutubePlay vidId={`https://www.youtube.com/watch?v=${module.id.videoId}`} />
                                        {/* <figure>
                                            <a href={`https://www.youtube.com/watch?v=${module.id.videoId}`}>
                                                <img src={module.snippet.thumbnails.medium.url}
                                                    alt={module.snippet.title} />
                                                <figcaption>
                                                    <h1>{module.snippet.title}</h1>
                                                    <p>Par: {module.snippet.channelTitle}</p>
                                                </figcaption>
                                            </a>
                                        </figure> */}
                                    </li>))
                            }

                        </ul>
                        <button className="actu-btn  mx-auto d-block mt-7 mb-3 waves-effect waves-orange waves-ripple" style={{marginTop: '100px'}}>
                            <a href="/videos" style={{ marginTop: -3, color: '#FFF' }}>VOIR PLUS</a> <i
                                className="fas fa-long-arrow-alt-right"></i></button>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Actualites;