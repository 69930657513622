
function Loader() {

    return (
        // <div className="load-div border-gradient mb-3 mt-3 text-center text-white" style={{ backgroundColor: "#108757", marginLeft: "500px", marginRight: "500px", }}>Chargement...<i className={classnames('fa fa-circle-o-notch', { 'fa-spin': true })}></i></div>
        <div className="row">
            <div class="three col">
                <div class="loader" id="loader-1"></div>
            </div>
        </div>
    )
}

export default Loader;