import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { postResource } from '../../services/api';

function Newstetter({ show, toggle }) {
    const [formData, setFormData] = useState({})
    const [afterSubmit, setAfterSubmit] = useState(false)

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const submitData = () => {
        postResource('new_letters', formData).then(res => {
            setFormData({})
            setAfterSubmit(true)
        })
    }

    return (
        <Modal isOpen={show} toggle={toggle} >
            <ModalHeader toggle={toggle}>Inscrivez-vous à la newsletter</ModalHeader>
            <ModalBody>

                {!afterSubmit && <div><div className="mb-3">
                    <small>Votre adresse de messagerie, votre nom et prénoms seront uniquement utilisés pour vous envoyer des informations relatives au MAEP.<br></br>
                        <a class="footer-copyright-link text-muted" href={process.env.REACT_APP_BASE_URL + '/content/mentions-legales'} >Vous pouvez à tout moment vous désabonner. </a> </small>
                    <label for="exampleFormControlInput1" className="form-label">Adresse e-mail
                        <small className="fw-bold">[obligatoire]</small></label>
                    <input required type="email" value={formData.email} className="form-control"
                        id="exampleFormControlInput1" placeholder="nom@agriculuture.gouv.bj" onChange={(event) => { setFormData({ ...formData, email: event.target.value }) }} />
                </div>
                    <div className="mb-3">
                        <label for="text1" className="form-label">Nom et prenom <small
                            className="fw-bold">[optionnel]</small></label>
                        <input type="text" value={formData.fullName} className="form-control" placeholder="Nom et Prénoms" id="text1" onChange={(event) => { setFormData({ ...formData, fullName: event.target.value }) }} />
                    </div>
                </div>}
                {afterSubmit && <div className="event event-card one-half alternate-4">

                    <div className="event-card-details ">
                        <div className="text-primary text-bold">Abonnement à la newsletter réalisé avec succès</div>


                    </div>
                </div>}

            
            </ModalBody>
            <ModalFooter>
                {!afterSubmit && <button type="button" className="btn btn-warning" disabled={!formData.email || !formData.fullName || !validateEmail(formData.email)} onClick={() => submitData()}>Envoyer <i className="fas fa-paper-plane"></i></button>}
            </ModalFooter>
           
        </Modal>

    )
}

export default Newstetter;